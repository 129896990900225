/* eslint-disable */
import React, { lazy, useEffect, useState } from "react";

// import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from "config.js";
import LoadingProcessView from "./views/child/LoadingProcessView";
import { ERROR_MESSAGE } from "./common/Helper";
import APIService from "./@mock-api/api.service";
import { useSnackbar } from "notistack";

const apiService = new APIService();

const dashboards = {
  elearning: lazy(() => import("views/dashboards/ElearningDashboard")),
  school: lazy(() => import("views/dashboards/SchoolDashboard")),
};
const courses = {
  explore: lazy(() => import("views/courses/CoursesExplore")),
  list: lazy(() => import("views/courses/CoursesList")),
  favourite: lazy(() => import("views/courses/FavouriteList")),
  detail: lazy(() => import("views/courses/CoursesDetail")),
  profile: lazy(() => import("views/provider/CompleteProfile")),
  add: lazy(() => import("views/courses/AddCourse")),
  providerProfile: lazy(() =>
    import("../src/views/provider/profile/ProviderProfile")
  ),
};
const child = {
  add: lazy(() => import("../src/views/child/add-child/AddChild")),
  view: lazy(() => import("views/child/ChildProfileView")),
  report: lazy(() => import("views/child/ReportCard")),
  profile: lazy(() => import("views/child/ChildProfile")),
  loading: lazy(() => import("views/child/LoadingProcessView")),
};

const admin = {
  parent: lazy(() => import("../src/views/admin/ParentsList")),
  dashboard: lazy(() => import("../src/views/admin/AdminDashboard")),
  freeSlotManagement: lazy(() =>
    import("../src/views/admin/FreeSlotManagement")
  ),
  FreeTalentAdvisoryCall: lazy(() =>
  import("../src/views/admin/FreeTalentAdvisoryCall")
),

FreeAssementUsers: lazy(() =>
import("../src/views/admin/FreeAssementUsers")
),

RegistrationDropoutUsers: lazy(() =>
import("../src/views/admin/RegistrationDropoutUsers")
),



PaymentTracker : lazy(() =>
import("../src/views/admin/PaymentTracker")
),

DownloadReport:lazy(()=>
import("../src/views/admin/DownloadReport")
),

ReportSection:lazy(()=>
import("../src/views/admin/ReportSection")
),




SchoolEnquiries : lazy(() =>
import("../src/views/admin/SchoolEnquiries")
),

Affiliates: lazy(() =>
import("../src/views/admin/affiliates/Affiliates")
),



CreatePaymentLink : lazy(() =>
import("../src/views/admin/CreatePaymentLink")
),

CreateExistingPaymentLink : lazy(() =>
import("../src/views/admin/CreateExistingPaymentLink")
),


PaymentLinkHistory : lazy(() =>
import("../src/views/admin/PaymentLinkHistory")
),



CounsellingCalls: lazy(() =>
import("../src/views/admin/CounsellingCalls")
),

TalentGuidanceUsers : lazy(() =>
import("../src/views/admin/TalentGuidanceUsers")
),


Coupons : lazy(() =>
import("../src/views/admin/Coupons")
),




ContactUs: lazy(() =>
import("../src/views/admin/ContactUs")
),



  subAdminList: lazy(() => import("views/admin/subAdmin/SubAdminList")),
  subAdminDetails: lazy(() => import("views/admin/subAdmin/SubAdminDetails")),
  registerRegime: lazy(() => import("views/admin/registerRegime/RegisterRegime")),
  slotManagementDetails: lazy(() => import("../src/views/admin/FreeSlotManagementDetails")),
  TalentGuidanceUsersManagementDetails: lazy(() => import("../src/views/admin/TalentGuidanceUsersManagementDetails")),
  celebrityData: lazy(() => import("../src/views/admin/CelebrityData")),
  viewAuditLogs: lazy(() => import("../src/views/admin/ViewAuditLogs")),
  feedbacks: lazy(() => import("../src/views/admin/Feedbacks")),
  finalLdScore: lazy(() => import("../src/views/admin/FinalLdScore")),
  archivedUsers: lazy(() => import("../src/views/admin/ArchivedUsers")),
  deletedUsers: lazy(() => import("../src/views/admin/DeletedUsers")),
  deletedUsersSurvey:lazy(() => import("../src/views/admin/DeletedUsersSurvey")),
  // referral: lazy(() => import('../src/views/admin/Referral')),
  reportedReviews: lazy(() => import("../src/views/admin/ReportedReviews")),
  provider: lazy(() => import("views/admin/ProvidersList")),
  enquiries: lazy(() => import("views/admin/ProviderEnquiry")),
  children: lazy(() => import("views/admin/childView/ChildList")),
  children1: lazy(() => import("views/admin/childView/UpgradeChild")),
  schoolList: lazy(() => import("views/admin/school/School")),
  parentDetails: lazy(() => import("views/admin/SingleParentDetails")),
  affiliateDetails:lazy(() => import("views/admin/affiliates/SingleAffilliateDetails")),
  courses: lazy(() => import("views/admin/CoursesList")),
  filterCourses: lazy(() => import("views/courses/NewCourseView")),
  providerDetails: lazy(() => import("views/admin/SingleProviderDetails")),
  earlyAccess: lazy(() => import("views/admin/ParentEarlyAccessList")),
  attributes: lazy(() => import("views/admin/Attributes")),
  schoolattributes: lazy(() => import("views/admin/SchoolAttributes")),
  parentattributes: lazy(() => import("views/admin/ParentAttributes")),
  courseattributes: lazy(() => import("views/admin/CourseAttributes")),
  manageSectionList: lazy(() => import("views/admin/manageSection/ManageSectionList")),
  sectionDetails: lazy(() => import("views/admin/manageSection/SectionDetails")),
  familyAttributes: lazy(() => import("views/admin/FamilyAttributes")),
  bulkUpload: lazy(() => import("views/admin/bulk-upload/index")),
  familyAttributesDetails: lazy(() =>
    import("views/admin/FamilyAttributesDetails")
  ),
  singleAttribute: lazy(() => import("views/admin/SingleAttributeDetail")),
  categories: lazy(() => import("views/admin/ProviderCategory")),
  onlineCourses: lazy(() => import("views/admin/OnlineCourses")),
  courseDetails: lazy(() => import("views/admin/CourseDetails")),
  list: lazy(() => import("views/admin/FaqList")),
  faqCategories: lazy(() => import("views/admin/FaqCategory")),
  notifications: lazy(() => import("views/admin/Notifications")),
  parentGroup: lazy(() => import("views/admin/group/ParentGroup")),
  childGroup: lazy(() => import("views/admin/group/ChildGroup")),
  groupDetail: lazy(() => import("views/admin/group/GroupDetail")),
  emailTemplate: lazy(() => import("views/admin/template/EmailTemplate")),
  templateDetail: lazy(() => import("views/admin/template/TemplateDetail")),
  eaLandingPage: lazy(() => import("views/admin/eaLandingPage/EaLandingPage")),
  landingPageDetails: lazy(() =>
    import("views/admin/eaLandingPage/LandingPageDetails")
  ),
  childDemographicsSections: lazy(() =>
    import("views/admin/childDemographicsSections")
  ),
  singleChild: lazy(() => import("views/admin/childView/ChildDetail")),
  courseDetail: lazy(() => import("../src/views/courses/1CoursesDetail")),
};

const teacher = {
  dashboard: lazy(() => import("../src/views/teacher/TeacherDashboard")),
  school: lazy(() => import("views/teacher/school/School")),
  combinedschoolreport: lazy(() => import("../src/views/teacher/combinedschoolreport/SchoolReport")),
  newpdf: lazy(() => import("../src/views/teacher/newpdf/NewPdfFile")),
};

const faq = {
  list: lazy(() => import("views/faq/Faq")),
};

const profile = {
  settings: lazy(() => import("views/child/settings")),
};

const appRoot = DEFAULT_PATHS.APP.endsWith("/")
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

function routesAndMenuItems(props) {
  const userDataPermission = JSON.parse(
    localStorage.getItem("userDataPermission")
  );

  let permission = {};
  let role = "";
  if (userDataPermission) {
    permission = userDataPermission.permissions;
    role = userDataPermission.role;
  }

  const permissionSubAdmin = (title) => {
    return !permission[title]
      ? false
      : permission[title]?.view || permission[title]?.edit;
  };

if(role === null || role === "admin"){
  return [
    {
      path: `${appRoot}/admin`,
      label: "Admin",
      icon: "online-class",
      exact: true,
      redirect: true,
      to: `${appRoot}/admin/dashboard`,
      subs: [
        { path: "/dashboard", label: "", component: admin.dashboard },
        { path: "/course/:id", label: "", component: admin.courseDetail },
        {
          path: "/attribute/:attribute_name",
          label: "",
          component: admin.singleAttribute,
        },
        {
          path: "/sectionDetails/:section",
          label: "",
          component: admin.sectionDetails,
        },
        {
          path: "/slotManagement/:id",
          label: "",
          component: admin.slotManagementDetails,
        },
        {
          path: "/TalentGuidanceslotManagement/:id",
          label: "",
          component: admin.TalentGuidanceUsersManagementDetails,
        },
        {
          path: "/courses/addCourse",
          label: "",
          component: admin.courseDetails,
        },
        {
          path: "/subAdminDetails/:id",
          label: "",
          component: admin.subAdminDetails,
        },
        { path: "/details/:id", label: "", component: admin.parentDetails },
        { path: "/affilliate-details/:id", label: "", component: admin.affiliateDetails },
        {
          path: "/deletedUsers/:id",
          label: "",
          component: admin.deletedUsersSurvey,
        },
        {
          path: "/familyAttribute/:name",
          label: "",
          component: admin.familyAttributesDetails,
        },
        permissionSubAdmin("sub_admin")
            ? {
              path: "/subAdminList",
              label: "Sub Admin",
              component: admin.subAdminList,
            }
            : "",
        permissionSubAdmin("parent")
            ? {
              path: "/parent",
              label: `Parent`,
              component: admin.parent,
              subs: [
                {
                  path: "/parents",
                  label: "Parents",
                  component: admin.parent,
                },
                {
                  path: "/early-access",
                  label: "Parent Early Access",
                  component: admin.earlyAccess,
                },
                {
                  path: "/archivedUsers",
                  label: "Archived Users ",
                  component: admin.archivedUsers,
                },
                permissionSubAdmin('deleted_users')? {
                  path: "/deletedUsers",
                  label: "Deleted Users ",
                  component: admin.deletedUsers,
                  
                }:"",
              ],
            }
            : "",
        permissionSubAdmin("provider")
            ? {
              path: "/provider",
              label: "Provider",
              component: admin.freeSlotManagement,
              subs: [
                {
                  path: "/providers",
                  label: "Providers",
                  component: admin.provider,
                },
                {
                  path: "/enquiries",
                  label: "Provider Enquiries",
                  component: admin.enquiries,
                },
              ],
            }
            : "",
            permissionSubAdmin("free_talent_advisory_call")
            ? {
              path: "/freeTalentadvisorycall",
              label: "Free Talent Advisory Call",
              component: admin.FreeTalentAdvisoryCall,
            }
            : "",

            permissionSubAdmin("free_assessment_users")
            ? {
              path: "/freeassementusers",
              label: "Free assement Users",
              component: admin.FreeAssementUsers,
            }
            : "",



            permissionSubAdmin("reg_dropouts")
            ? {
              path: "/registrationdropoutusers",
              label: "Registration Dropout Users",
              component: admin.RegistrationDropoutUsers,
            }
            : "",

            permissionSubAdmin("school_enquiries")
            ? {
              path: "/school-enquiry",
              label: "School Enquiry form",
              component: admin.SchoolEnquiries,
            }
            : "",

            permissionSubAdmin("report_section")
            ? {
              path: "/report-section",
              label: "Report section",
              component: admin.ReportSection,
            }
            : "",



            permissionSubAdmin("payment_tracker")
            ? {
              path: "/payment-tracker",
              label: "Payment tracker",
              component: admin.PaymentTracker,
            }
            : "",


            
            permissionSubAdmin("affiliates")
            ? {
              path: "/affiliates",
              label: "Affiliates",
              component: admin.Affiliates,
            }
            : "",

            permissionSubAdmin("download_report")
            ? {
              path: "/download-report",
              label: "Download Report",
              component: admin.DownloadReport,
            }
            : "",

            permissionSubAdmin("child_upgrade")
            ? {
              path: "/child-upgrade",
              label: "Child Upgrade",
              component: admin.children1,
            }
            : "",


            permissionSubAdmin("payment_link")
            ? {
              path: "/paymentlink",
              label: "Payment Link",
              component: admin.CreatePaymentLink,
              subs: [
                {
                  path: "/createpaymentlink",
                  label: "New User",
                  component: admin.CreatePaymentLink,
                },
                {
                  path: "/createexistingpaymentlink",
                  label: "Existing User",
                  component: admin.CreateExistingPaymentLink,
                }, 
                {
                  path: "/paymentlinkhistory",
                  label: "Payment Link History",
                  component: admin.PaymentLinkHistory,
                }
              ],
            }
            : "",


            permissionSubAdmin("counselling_calls")
            ? {
              path: "/counselingcall",
              label: "Counseling Call",
              component: admin.CounsellingCalls,
            }
            : "",


            permissionSubAdmin("coupons")
            ? {
              path: "/coupons",
              label: "Coupons",
              component: admin.Coupons,

            }
            : "",

            permissionSubAdmin("contact_us")
            ? {
              path: "/contact-us",
              label: "Contact Us Page Requests",
              component: admin.ContactUs,
            }
            : "",
        permissionSubAdmin("children")
            ? {
              path: "/child",
              label: `Child`,
              component: admin.children,
              subs: [
                {
                  path: "/children",
                  label: "Children(s)",
                  component: admin.children,
                },
              ],
            }
            : "",
        permissionSubAdmin("school")
            ?
            {
              path: "/school",
              label: `School`,
              component: admin.celebrityData,
              subs: [
                {
                  path: "/student",
                  label: "Student",
                  component: teacher.school,
                },{
              path: "/school",
              label: "School",
              component: admin.schoolList,
            }]}
            : "",
        permissionSubAdmin("free_slot")
            ? {
              path: "/freeSlotManagement",
              label: "Free Slot Management",
              component: admin.freeSlotManagement,
              subs: [
                {
                  path: "/freeSlotManagement",
                  label: "Free Slot Management",
                  component: admin.freeSlotManagement,
                },
              ],
            }
            : "",
        permissionSubAdmin("cms")
            ? {
              path: "/cms",
              label: `CMS`,
              component: admin.celebrityData,
              subs: [
                {
                  path: "/attributes",
                  label: "Child Attributes",
                  component: admin.attributes,
                },
                {
                  path: "/school-attributes",
                  label: "School Attributes",
                  component: admin.schoolattributes,
                },
                  {
                      path: "/parent-attributes",
                      label: "Parent Attributes",
                      component: admin.parentattributes,
                  },
                {
                  path: "/course-attributes",
                  label: "Course Attributes",
                  component: admin.courseattributes,
                },
                {
                  path: "/manageSectionList",
                  label: "Manage Section",
                  component: admin.manageSectionList,
                },
                {
                  path: "/categories",
                  label: "Categories",
                  component: admin.categories,
                },
                {
                  path: "/celebrityData",
                  label: "Celebrity Data",
                  component: admin.celebrityData,
                },
                {
                  path: "/viewAuditLogs",
                  label: "View Audit Logs",
                  component: admin.viewAuditLogs,
                },
                {
                  path: "/eaLandingPage",
                  label: `EA Landing Page`,
                  component: admin.eaLandingPage,
                  subs: [
                    {
                      path: "/eaLandingPage",
                      label: "EA Landing Page",
                      component: admin.eaLandingPage,
                    },
                    {
                      path: "/:id",
                      label: "",
                      component: admin.landingPageDetails,
                    },
                  ],
                },
                {
                  path: "/template",
                  label: `Email Template`,
                  component: admin.emailTemplate,
                  subs: [
                    {
                      path: "/add",
                      label: "Create Template",
                      component: admin.templateDetail,
                    },
                    {
                      path: "/email",
                      label: "Existing Templates",
                      component: admin.emailTemplate,
                    },
                    {
                      path: "/:id",
                      label: "",
                      component: admin.templateDetail,
                    },
                  ],
                },
                {
                  path: "/faq",
                  label: `FAQ's`,
                  component: admin.list,
                  subs: [
                    {
                      path: "/list",
                      label: "FAQ List",
                      component: admin.list,
                    },
                    {
                      path: "/category",
                      label: "FAQ Categories",
                      component: admin.faqCategories,
                    },
                  ],
                },
                {
                  path: "/group",
                  label: `Groups`,
                  component: admin.parentGroup,
                  subs: [
                    {
                      path: "/parent",
                      label: "Parent Group",
                      component: admin.parentGroup,
                    },
                    {
                      path: "/child",
                      label: "Children Group",
                      component: admin.childGroup,
                    },
                    {
                      path: "/:id",
                      label: "",
                      component: admin.groupDetail,
                    },
                  ],
                },
                {
                  path: "/notifications",
                  label: "Notifications",
                  component: admin.notifications,
                },
                {
                  path: "/familyAttributes",
                  label: "Family Attributes",
                  component: admin.familyAttributes,
                },
                {
                  path: "/finalLdScore",
                  label: "LD % Contribution ",
                  component: admin.finalLdScore,
                },
              ],
            }
            : "",

        permissionSubAdmin("cms")
            ? {
              path: "/bulk-upload",
              label: "Bulk Upload",
              component: admin.bulkUpload,
            }
            : "",
        permissionSubAdmin("feedback")
            ? {
              path: "/feedbacks",
              label: `Feedback`,
              component: admin.courses,
              subs: [
                {
                  path: "/feedbacks",
                  label: "Feedbacks ",
                  component: admin.feedbacks,
                },
                {
                  path: "/reportedReviews",
                  label: "Reported Reviews ",
                  component: admin.reportedReviews,
                },
              ],
            }
            : "",
        permissionSubAdmin("courses")
            ? {
              path: "/courses",
              label: `Courses`,
              component: admin.courses,
              subs: [
                {
                  path: "/courses",
                  label: "Listed Courses",
                  component: admin.courses,
                },
                {
                  path: "/course-filter",
                  label: "",
                  component: admin.filterCourses,
                },
                {
                  path: "/online-courses",
                  label: "Online Courses",
                  component: admin.onlineCourses,
                },
                {
                  path: "/addCourse",
                  label: "Add Course",
                  component: admin.courseDetails,
                },
              ],
            }
            : "",
        permissionSubAdmin("regime")
            ? {
              path: "/registerRegime",
              label: "Register Regime",
              component: admin.registerRegime,
            }:"",
        {
          path: "/childDetail/:id",
          label: "",
          component: admin.singleChild,
        },
        {
          path: "/courses/:id",
          label: "",
          component: admin.courseDetails,
        },
      ],
    },
  ]
}

if(role === "teacher" ){
  return [
    {
      path: `${appRoot}/teacher`,
      label: "Teacher",
      icon: "online-class",
      exact: true,
      redirect: true,
      to: `${appRoot}/teacher/dashboard`,
      subs: [
        { path: "/dashboard", label: "", component: teacher.dashboard },
        {
          path: "/school",
          label: "School",
          component: teacher.school,
        },
        {
          path: "/newpdf",
          label: "",
          component: teacher.newpdf,
        },
        {
          path: "/combinedschoolreport",
          label: "",
          component: teacher.combinedschoolreport,
        },
        {
          path: "/childDetail/:id",
          label: "",
          component: admin.singleChild,
        },
      ],
    },
  ]
}
else{
  return []
  }




};

export default routesAndMenuItems;
