// cra imports
import React, {useMemo} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals.js';
// import redux requirements
import {Provider} from 'react-redux';
import {PersistGate} from 'reduxjs-toolkit-persist/integration/react';
import {persistedStore, store} from 'store.js';
// import html head tags requirements
import {Helmet} from 'react-helmet';
import {REACT_HELMET_PROPS} from 'config.js';
// import multi language
import LangProvider from 'lang/LangProvider';
// import routing modules
import {BrowserRouter as Router} from 'react-router-dom';
import RouteIdentifier from 'routing/components/RouteIdentifier';
import Loading from 'components/loading/Loading';
// import routes
import {getLayoutlessRoutes} from 'routing/helper';
import defaultRoutes from 'routing/default-routes';
import routesAndMenuItems from 'routes.js';
// import toastify for notification
import {Slide, ToastContainer} from 'react-toastify';
// mock server register for demo
import '@mock-api';
//Apollo client
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {SnackbarProvider} from "notistack";

const httpLink = createHttpLink({
    uri: 'http://localhost:8000'
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
});

const Main = () => {
    const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({data: routesAndMenuItems}), []);
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}>
                <PersistGate loading={null} persistor={persistedStore}>

                    <Helmet {...REACT_HELMET_PROPS}/>
                    <ToastContainer transition={Slide} newestOnTop/>
                    <Router basename={process.env.REACT_APP_BASENAME}>
                        <LangProvider>
                            <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} fallback={<Loading/>}/>
                        </LangProvider>
                    </Router>
                </PersistGate>
            </SnackbarProvider>
        </Provider>
    );
};

ReactDOM.render(<ApolloProvider client={client}> <Main/> </ApolloProvider>, document.getElementById('root'));

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
