export const GQ_CREATE_PROVIDER_ENQUIRY = `mutation Mutation($provider: ProviderInput) {
  createProviderInquiry(provider: $provider) {
    id
  }
}`;

export const GQ_COMPLETE_PROVIDER_PROFILE = `mutation Mutation($details: BusinessDetailInput) {
  createBusinessDetail(details: $details) {
    id
  }
}`;


export const GQ_UPDATE_PROVIDER_PROFILE = `mutation Mutation($details: BusinessDetailInput) {
  updateBusinessDetail(details: $details) {
    id
    user
    logo
    business_name
    website
    foundation_year
    director_name
    category
    location
    about
    program_listing_type {
      id
      name
    }
    document
    first_name
    last_name
    email
    phone
    designation
    bank_name
    bank_account
    bank_holder
    bank_ifsc
    corporate_email
    llp_number
    gst_number
  }
}`;


export const GQ_GET_PROVIDER_PROFILE = `query Query{
  getBusinessByProvider {
    corporateEmailVerifiedAt
    corporatePhoneVerifiedAt
    gst_number
    llp_number
     phone {
      number
      code
      name
    }
    corporate_phone {
      number
      code
      name
    }
    corporate_email
    bank_code_type
    bank_ifsc
    bank_holder
    bank_account
    bank_name
   
    banner
    contactEmailVerifiedAt
    contactPhoneVerifiedAt
    designation
    email
    last_name
    first_name
    document
    program_listing_type {
      id
      name
    }
    about
    location
    category {
      category_id
      category
    }
    foundation_year
    director_name
    website
    business_name
    logo
    user {
      id
    }
    id
  }
}`;

export const GQ_UPDATE_COMPANY_DETAILS = `mutation Mutation($details: BusinessCompanyInput) {
  updateCompanyDetails(details: $details) {
    id 
  }
}`;

export const GQ_GET_PROGRAM_TYPE = `query Query {
  getAllProgramListing {
    id
    name
  }
}`;

export const GQ_GET_PROGRAM_TYPE_ADMIN = `query Query {
  getAllProgramListing {
    id
    name
    isApproved
  }
}`;

export const GQ_ADD_PROGRAM_TYPE = `mutation AddProgramListing($name: String, $isApproved: Int) {
  addProgramListing(name: $name, isApproved: $isApproved) {
    id
    name
  }
}`;

export const GQ_SEARCH_PROGRAM_TYPE = `query Query($query: String) {
  getProgramListing(query: $query) {
    id
    name
  }
}`;

export const GQ_UPDATE_PROVIDER_PROGRAM_TYPE = `mutation Mutation($details: BusinessProgramInput) {
  updateBusinessProgram(details: $details) {
    id
  }
}`;

export const GQ_UPDATE_PROVIDER_CATEGORY = `mutation Mutation($details: BusinessCategoryInput) {
  updateBusinessCategories(details: $details) {
    id
  }
}`;


export const GQ_SEARCH_PROVIDER_CATEGORY = `query Query($query: String) {
  getProviderCategory(query: $query) {
    id
    category
  }
}`;

export const GQ_ADD_NEW_CATEGORY = `mutation AddProviderCategory($category: String, $isApproved: Int) {
  addProviderCategory(category: $category, isApproved: $isApproved) {
    id
    category
  }
}`;

export const GQ_UPDATE_PROVIDER_CONTACT_INFO = `mutation UpdateBusinessContact($details: BusinessContactInput) {
  updateBusinessContact(details: $details) {
    id
  }
}`;

export const GQ_SEND_OTP_BUSINESS_EMAIL = `mutation Mutation($email: String, $type: Int) {
  sendBusinessEmailVerification(email: $email, type: $type) {
    otp
  }
}`;


export const GQ_SEND_OTP_BUSINESS_PHONE = `mutation Mutation($phone: String, $type: Int) {
  sendBusinessPhoneVerification(phone: $phone, type: $type) {
    otp
  }
}`;

export const GQ_VERIFY_BUSINESS_EMAIL = `mutation Mutation($email: String, $otp: Int, $type: Int) {
  verifyBusinessEmail(email: $email, otp: $otp, type: $type) {
   id 
  }
}`;

export const GQ_VERIFY_BUSINESS_PHONE = `mutation Mutation($otp: Int, $phone: String, $type: Int) {
  verifyBusinessPhone(otp: $otp, phone: $phone, type: $type) {
    id
  }
}`;


export const GQ_UPDATE_BANK_DETAILS = `mutation Mutation($details: BusinessBankInput) {
  updateBusinessBankDetails(details: $details) {
    id
  }
}`;

export const GQ_UPDATE_CORPORATE_DETAILS = `mutation Mutation($details: BusinessCorporateInput) {
  updateBusinessCorporateDetails(details: $details) {
    id
  }
}`;


export const GQ_UPDATE_PROVIDER_DOCUMENT = `mutation Mutation($details: BusinessDocumentInput) {
  updateBusinessDocuments(details: $details) {
   id 
  }
}`;

export const GQ_GET_COURSES_BY_PROVIDER = `query Query {
  getCoursesByProvider {
    name
    category {
      id
      category
    }
    headline
    fees
    fees_type
    id
  }
}`;

export const GQ_UPDATE_PROVIDER_LOGO_BANNER = `mutation UpdateBusinessLogoAndBanner($details: BusinessMediaInput) {
  updateBusinessLogoAndBanner(details: $details) {
    logo
    banner
  }
}`;

export const GQ_GET_ALL_DESIGNATION = `query Query{
  getAllDesignations {
    name
  }
}`;

export const GQ_SEARCH_DESIGNATION = `query Query($query: String) {
  getDesignation(query: $query) {
    name
  }
}`;

export const GQ_ADD_NEW_DESIGNATION = `mutation AddDesignation($name: String) {
  addDesignation(name: $name) {
    name
  }
}`;
