export const GQ_GET_CATEGORIES = `query Query {
  getAllCategories {
    category
    id
  }
}`;

export const GQ_GET_PROVIDER_CATEGORIES = `query GetAllCategories {
  getAllProviderCategory {
    category
    id
  }
}`;

export const GQ_SET_MEDIA_LOG = `mutation FileUpload($path: String, $mimetype: String, $type: String) {
  fileUpload(path: $path, mimetype: $mimetype, type: $type) {
    id
  }
}`;

export const GQ_GET_STORED_MEDIA_BY_TYPE = `query GetSavedImageByType($query: String) {
  getSavedImageByType(query: $query) {
    id
    path
    mimetype
  }
}`;


