import axios from 'axios';
import {
    GQ_EMAIL_VERIFICATION,
    GQ_FORGOT_PASSWORD,
    GQ_GENERATE_OTP,
    GQ_GENERATE_OTP_PARENT,
    GQ_GENERATE_PARENT_TOKEN,
    GQ_LOGIN,
    GQ_PARENT_EARLY_ACCESS,
    GQ_PARENT_EMAIL_VERIFICATION,
    GQ_PROVIDER_EMAIL_VERIFICATION,
    GQ_PROVIDER_REGISTER,
    GQ_REGISTER,
    GQ_RESET_PASSWORD,
    GQ_GENERATE_PARENT_PASSWORD,
    GQ_SENT_ADMIN_PASSWORD_RESET_OTP,
    GQ_SENT_ADMIN_VERIFICATION_OTP,
    GQ_VERIFY_ADMIN_EMAIL,
    GQ_VERIFY_ADMIN_FORGOT_EMAIL,
    GQ_VERIFY_OTP,
    GQ_VERIFY_PARENT_OTP,
    GQ_VERIFY_PROVIDER_OTP
} from "./graphql/register";
import {
    GET_ALL_FIELDS,
    GET_CHILD_EARNED_COINS,
    GET_CHILD_FIELDS,
    GET_CHILD_TOTAL_EARNED_COINS,
    GQ_ADD_CHILD,
    GQ_ADD_CHILD_GLOBAL_ATTRIBUTE,
    GQ_ADD_COURSE_TO_WISHLIST,
    GQ_ADD_HABIT,
    GQ_ADD_HOBBY,
    GQ_ADD_INTEREST,
    GQ_ADD_ONLINE_COURSE,
    GQ_ADD_ONLINE_COURSE_PROVIDER,
    GQ_ADD_SCHOOL,
    GQ_CREATE_NEW_CHILD,
    GQ_GET_ALL_HOBBIES,
    GQ_GET_ALL_INTEREST,
    GQ_GET_ALL_NOTIFICATIONS,
    GQ_GET_ALL_ONLINE_COURSE,
    GQ_GET_ALL_ONLINE_COURSES_BY_CHILD,
    GQ_GET_ALL_SCHOOL,
    GQ_GET_AVATAR,
    GQ_GET_CHILD,
    GQ_GET_CHILD_ASSIGNED_ATTRIBUTES,
    GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD,
    GQ_GET_CHILD_CERTIFICATES,
    GQ_GET_CHILD_DEMO_COURSES,
    GQ_GET_CHILD_LIKED_COURSES,
    GQ_GET_CHILD_ONGOING_COURSES,
    GQ_GET_CHILD_REPORT_CARDS,
    GQ_GET_CHILD_SCHOOLS,
    GQ_GET_COURSES,
    GQ_GET_DIMENSION_ANSWERED_QUESTION,
    GQ_GET_CHILD_ALL_ADVISORY_CALL,
    GQ_PROVIDER_BULK_INSERT,
    GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE,
    GQ_GET_GLOBAL_CHILD_ATTRIBUTES,
    GQ_GET_HABITS,
    GQ_GET_HOBBIES,
    GQ_GET_INTEREST,
    GQ_GET_ONLINE_COURSE,
    GQ_GET_OVERVIEW_TEMPLATE,
    GQ_GET_POLYGON_ANALYSIS_REPORT,
    GQ_GET_SCHOOL,
    GQ_GET_SINGLE_CHILD_DETAIL,
    GQ_GET_SKILLS,
    GQ_GET_SUBJECTS,
    GQ_GET_WISHLIST_COURSES,
    GQ_IMAGE_UPLOAD,
    GQ_IMAGE_UPLOAD_ALREADY_PAID,
    GQ_REMOVE_COURSE_FROM_WISHLIST,
    GQ_SEARCH_COURSE_PROVIDERS,
    GQ_SEARCH_HABIT,
    GQ_SINGLE_COURSE_DETAIL,
    GQ_UPDATE_BASIC_INFO,
    GQ_UPDATE_CHILD_IMAGE_BANNER,
    GQ_UPDATE_EDUCATIONAL_INFO,
    GQ_UPDATE_HABITS,
    GQ_UPDATE_SKILLS,
    GQ_UPDATE_SUBJECTS,
    GQ_UPLOAD_FILE,
    GQ_GET_TDP_REPORT,
    GET_MULTI_DIMENSION_COGNITIVE_REPORT,
    GQ_GET_DIMENSION_COGNITIVE_REPORT,
    GQ_GET_LEARNING_NEEDS,
    GQ_GET_TALENT_NEEDS,
    GQ_GET_TRAITS_MAPPING,
    GQ_GET_CAREERS_MAPPING,
    GQ_GET_ELASTIC_SEARCH_COURSES,
} from "./graphql/child";
import {
    GQ_GET_ALL_COURSES,
    GQ_CREATE_COURSE_WITH_ALL_INFO,
    GQ_EDIT_COURSE_WITH_ALL_INFO,
    GQ_CREATE_COURSE_ATTRIBUTE,
    GQ_GET_COURSE_ATTRIBUTES,
    GQ_GET_USERS,
    GQ_GET_ALL_PROVIDERS_WITH_BUSINESS,
    GQ_GET_SINGLE_USER_DETAIL,
    GQ_GET_CHILD_BY_PARENT,
    GQ_GET_CHILD_BY_PARENT_NEW,
    GQ_GET_CHILD_UPDATE,
    GQ_GET_PROVIDER_ENQUIRIES,
    GQ_UPDATE_PROVIDER_STATUS,
    GQ_GET_ALL_CHILDREN,
    GQ_GET_PARENT_EARLY_ACCESS,
    GQ_GENERATE_SINGLE_LOGIN,
    GQ_GENERATE_BULK_LOGIN,
    GQ_SEND_EMAIL_PHONE_VERIFICATION,
    GQ_FETCH_GROUP_ATTRIBUTES,
    GQ_CREATE_CHILD_ATTRIBUTE,
    GQ_UPDATE_CHILD_SINGLE_ATTRIBUTE,
    GQ_SINGLE_ATTRIBUTE_DETAILS,
    GQ_DELETE_PROVIDER_CATEGORY,
    GQ_DELETE_CHILD_ATTRIBUTE,
    GQ_DELETE_CHILD_ATTRIBUTE_BY_ID,
    GQ_DELETE_ONLINE_COURSE,
    GQ_GET_PROVIDER_CATEGORIES_ADMIN,
    GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID,
    GQ_UPDATE_CHILD_ATTRIBUTE,
    GQ_APPROVE_PROVIDER_CATEGORY,
    GQ_GET_FAQ_CATEGORY,
    GQ_ADD_FAQ_CATEGORY,
    GQ_EDIT_FAQ_CATEGORY,
    GQ_DELETE_FAQ_CATEGORY,
    GQ_ADD_SITE_FAQ,
    GQ_EDIT_SITE_FAQ,
    GQ_DELETE_SITE_FAQ,
    GQ_GET_ALL_SITE_FAQ,
    GQ_DELETE_PROGRAM_TYPE,
    GQ_APPROVE_PROGRAM_TYPE,
    GQ_ADMIN_SEND_NOTIFICATION,
    GQ_ADD_SITE_FAQ_BULK,
    GQ_SEND_MARKETTING_EMAIL,
    GQ_ADD_TEMPLATE_EMAIL,
    GQ_EDIT_TEMPLATE_EMAIL,
    GQ_DELETE_TEMPLATE_EMAIL,
    GQ_GET_ALL_EMAIL_TEMPLATES,
    GQ_GET_ALL_MARKETING_EMAILS,
    GQ_ADMIN_SEND_EMAIL,
    GQ_ADMIN_ADD_GROUP,
    GQ_GET_USER_GROUP,
    GQ_DELETE_USER_FROM_GROUP,
    GQ_GET_GROUP_BY_ID,
    GQ_UPDATE_GROUP_DATA,
    GQ_DELETE_GROUP,
    GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS,
    GQ_CREATE_EA_LANDING_PAGE,
    GQ_GET_ALL_EA_LANDING_LIST,
    GQ_EDIT_EA_LANDING_PAGE,
    GQ_DELETE_EA_LANDING_PAGE_DETAILS,
    GET_CHILD_MANAGE_SLOTS,
    GET_TALENT_GUIDANCE,
    GET_FREE_ADIVISORY_CALL,
    GET_COUNSELING_CALL,
    GET_COUPUNS,
    GET_ALL_PAYMENT_LINK,
    GET_ALL_SCHOOL_ENQIRY,
    GET_EXPORT_DATA,
    GQ_APPLYCOPUN,
    GET_FREE_ASSESSMENT_USERS,
    GET_REG_DROPOUT_USERS,
    GET_CONTACT_US,
    GET_PAYMENT_TRACKER,
    GQ_UPDATE_TALENT_DISCOVERY_DATA,
    GQ_UPDATE_COUNSELLING_DATA,
    
    GQ_UPDATE_TALENT_GUIDANCE_STATUS,
    GQ_CREATE_COPUN,
    GQ_TDA_PAYEMENTUSER,
    GQ_CHECKEXISTINGUSER,
    GQ_UPDATE_BILING_INFO,
    GQ_UPDATE_BILING_INFO2,
    GQ_PAYMENT_STATUS,
    GQ_CREATEADMINORDER,
    GQ_ADMIN_ORDER_TDA,
    GQ_ADMIN_CREATE_AFFILIATES,
    GQ_EDIT_COPUN,
    GQ_DELETE_COPUN,
    GQ_UPDATE_CONTACT_US,
    GQ_TOTAL_USERS,
    GQ_TOTAL_PAID_USERS,
    GQ_RECENT_PAYMENTS,
    GQ_TOTAL_PAYMENT_COLLECTED,
    GQ_TOTAL_REPORT_GENERATED,
    GET_CHILDREN_FREE_SLOTS,
    GET_CHILDREN_TALENT_GUIDANCE_SLOTS,
    GQ_SCHEDULE_CHILDREN_REQUEST,
    GET_CHILDREN_BOOKING_REQUEST,
    GQ_GET_ALL_EA_LANDING_ICON_LIST,
    GQ_UPDATE_BULLET_POINT_ICONS,
    GQ_GET_ALL_FEEDBACK,
    GQ_GET_ALL_REPORTED_REVIEW,
    GQ_SCHEDULE_DEMO_CHILDREN_REQUEST,
    GQ_UPDATE_SECTION_ORDERING,
    GQ_GET_SELECTED_EA_REGIME_PREFERENCE,
    GQ_UPDATE_EA_REGIME_PREFERENCE,
    GQ_GET_FIELDS_GROUP,
    GQ_EDIT_SCHEDULE_DEMO_CHILDREN_REQUEST,
    GQ_EDIT_SCHEDULE_CHILDREN_REQUEST,
    GQ_GET_ALL_EMAIL_TEMPLATES_TYPES,
    GQ_GET_DEMO_REVIEW_BY_REQUEST_ID,
    GQ_GET_LD_PERCENTAGE_CONTRIBUTION,
    GQ_UPDATE_LD_CONTRIBUTION,
    GQ_ENABLE_DISABLE_COURSE,
    GQ_GET_COURSE_REVIEWS,
    GQ_MARK_USER_AS_ARCHIVED,
    GQ_DELETE_ARCHIVED_USER,
    GQ_ARCHIVED_USERS,
    GQ_ADMIN_ACTIVITY_LOGS,
    GQ_DELETED_USERS,
    GQ_EXPORT_EARLY_ACCESS_USERS,
    GQ_EXPORT_NO_CHILD_USERS,
    GQ_EXPORT_COMPLETE_CHILD_USERS,
    GQ_EXPORT_ASSESSMENT_COM_REPORT,
    GQ_EXPORT_INCOMPLETE_CHILD_USERS,
    GQ_EXPORT_PAYMENT_TRACKER_USERS,
    GET_FIELD_GROUP,
    GQ_UPDATE_SECTION_INFORMATION,
    GQ_UPDATE_SECTION_FIELD_INFORMATION,
    GQ_GET_ALL_TEACHER_ASSIGNED_STUDENTS,
    GQ_GET_RESET_EMAIL,
    GQ_GET_Delete_Child,
    GQ_INVITE_NEW_STUDENT,
    GQ_SEND_STUDENT_REMINDER,
    GQ_GET_ALL_SCHOOL_USERS,
    GQ_UPDATE_SCHOOL_SESSION,
    GQ_SESSION_PROVIDER_EMAIL,
    GQ_GENERATE_PDF_REPORT_ID,
    GQ_ALLOW_CHILD_REPORT_ACCESS,
    GQ_APPROVE_SCHOOL_ATTRIBUTE_BY_ID,
    GQ_UPDATE_SCHOOL_SINGLE_ATTRIBUTE,
    GQ_UPDATE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID,
    GQ_DELETE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID,
    GQ_APPROVE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID,
    GQ_FETCH_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTES,
    GQ_DELETE_SCHOOL_ATTRIBUTE_BY_ID,
    GQ_FETCH_COURSE_ATTRIBUTES,
    GQ_FETCH_ONLINE_COURSE_ATTRIBUTES,
    GQ_ACCESS_NOW_ADMIN,
    GQ_UPDATE_ONLINE_COURSE_ATTRIBUTES, GQ_DELETE_ONLINE_COURSE_ATTRIBUTES, GQ_APPROVE_ONLINE_COURSE_ATTRIBUTES,
    GQ_DOWNLOAD_INVOICE,
    GQ_DELETED_USERS_SURVEY,
    GQ_UPDATE_PUNCHING_DETAILS,
    GQ_GET_TOTAL_TAC_USERS,
    GQ_GET_TOTAL_PAYMENT_LINK_GENERATED,
    GQ_MARK_AS_TESTING_PAYMENT,
    GQ_GET_TOTAL_CHILDREN,
    GQ_GET_PREMIUM_REPORT_GENERTAED,
    GQ_UPDATE_CHILD_REPORT_STATUS,
    GQ_EXPORT_DOWNLOAD_REPORT_DATA,
    GQ_EXPORT_GET_ALL_AFFILLIATES,
    GQ_CHILD_ASSESSMENT_INFO_DATA ,
    GQ_SAVE_ALREADY_PAID_PAYMENT_DETAILS,
    GET_ALL_REFERRRAL_LINKS,
    GQ_ADMIN_DELETE_AFFILIATES,
    GQ_ADMIN_INACTIVE_AFFILIATES,
    GQ_ADMIN_EDIT_AFFILIATES
} from "./graphql/admin";
import {
    GQ_ADD_NEW_CATEGORY, GQ_ADD_NEW_DESIGNATION,
    GQ_ADD_PROGRAM_TYPE,
    GQ_COMPLETE_PROVIDER_PROFILE,
    GQ_CREATE_PROVIDER_ENQUIRY, GQ_GET_ALL_DESIGNATION, GQ_GET_COURSES_BY_PROVIDER,
    GQ_GET_PROGRAM_TYPE, GQ_GET_PROGRAM_TYPE_ADMIN,
    GQ_GET_PROVIDER_PROFILE, GQ_SEARCH_DESIGNATION, GQ_SEARCH_PROGRAM_TYPE,
    GQ_SEARCH_PROVIDER_CATEGORY, GQ_SEND_OTP_BUSINESS_EMAIL, GQ_SEND_OTP_BUSINESS_PHONE, GQ_UPDATE_BANK_DETAILS,
    GQ_UPDATE_COMPANY_DETAILS, GQ_UPDATE_CORPORATE_DETAILS,
    GQ_UPDATE_PROVIDER_CATEGORY,
    GQ_UPDATE_PROVIDER_CONTACT_INFO, GQ_UPDATE_PROVIDER_DOCUMENT, GQ_UPDATE_PROVIDER_LOGO_BANNER,
    GQ_UPDATE_PROVIDER_PROFILE,
    GQ_UPDATE_PROVIDER_PROGRAM_TYPE, GQ_VERIFY_BUSINESS_EMAIL, GQ_VERIFY_BUSINESS_PHONE
} from "./graphql/provider";
import {
    GQ_GET_CATEGORIES,
    GQ_GET_PROVIDER_CATEGORIES,
    GQ_GET_STORED_MEDIA_BY_TYPE,
    GQ_SET_MEDIA_LOG
} from "./graphql/common";
import {
    GQ_ADD_COMMUNICATION_MODES,
    GQ_COURSE_CHARGES, GQ_CREATE_COURSE, GQ_GET_ALL_PARENT_FAQ, GQ_GET_ALL_PARENT_SITE_FAQ,
    GQ_GET_COMMUNICATION_MODES,
    GQ_GET_COURSE, GQ_GET_COURSE_MEDIA, GQ_GET_PARENT_FAQ_CATEGORY,
    GQ_UPDATE_COURSE_LOGO_BANNER,
} from "./graphql/course";
import {
    GQ_CREATE_SUB_ADMIN, GQ_DELETE_SUB_ADMIN, GQ_GET_ALL_SUB_ADMINS,
    GQ_GET_ATTRIBUTE_WEIGHTAGE,
    GQ_GET_FAMILY_ATRRIBUTE,
    GQ_GET_FAMILY_ATTRIBUTE_DETAILS, GQ_GET_SUB_ADMIN_BY_ID,
    GQ_UPDATE_ATTRIBUTE_WEIGHTAGE, GQ_UPDATE_SUB_ADMIN_PERMISSIONS
} from "./graphql/attributes";
import {GQ_CREATE_CELEBRITY, GQ_DELETE_CELEBRITY, GQ_GET_ALL_CELEBRITY, GQ_UPDATE_CELEBRITY} from "./graphql/celebrity";

const baseURL = process.env.REACT_APP_API_ENDPOINT;


const headers = async () => {
    const headersData = {
        'Content-Type': 'application/json',
        'Web-Authorization': 'admin'
    };

    const token = await localStorage.getItem('aark_auth')
    if (token) {
        headersData.Authorization = `Bearer ${token}`;
    }

    return headersData;
};

const request = async (method, variables, query, upload) => {
    const url = `${baseURL}`;
    const options = {method, url, headers: await headers()};

    if (variables) {
        if (upload) {
            console.log('variable is', variables)
            options.data = variables
        } else {
            options.data = {
                query: query,
                variables: variables
            };
        }
    }
    return axios(options);
};

export default class APIService {
    //Register Flow API's
    login(data) {
        return request('POST', data, GQ_LOGIN);
    }

    sentAdminVerificationOTP(data) {
        return request('POST', data, GQ_SENT_ADMIN_VERIFICATION_OTP);
    }

    verifyAdminEmail(data) {
        return request('POST', data, GQ_VERIFY_ADMIN_EMAIL);
    }

    register(data) {
        return request('POST', data, data.user.role === 'user' ? GQ_REGISTER : GQ_PROVIDER_REGISTER);
    }

    // EMAIL VERIFICATION
    emailVerification(data) {
        return request('POST', data, GQ_EMAIL_VERIFICATION);
    }

    providerEmailVerification(data) {
        return request('POST', data, GQ_PROVIDER_EMAIL_VERIFICATION);
    }

    parentEmailVerification(data) {
        return request('POST', data, GQ_PARENT_EMAIL_VERIFICATION);
    }

    generateOTP(data) {
        return request('POST', data, GQ_GENERATE_OTP);
    }

    generateOtpParent(data) {
        return request('POST', data, GQ_GENERATE_OTP_PARENT);
    }

    parentEarlyAccess(data) {
        return request('POST', data, GQ_PARENT_EARLY_ACCESS);
    }

    verifyProviderOTP(data) {
        return request('POST', data, GQ_VERIFY_PROVIDER_OTP);
    }

    verifyOTP(data) {
        return request('POST', data, GQ_VERIFY_OTP);
    }

    verifyParentOTP(data) {
        return request('POST', data, GQ_VERIFY_PARENT_OTP);
    }

    generateParentToken(data){
        return request('POST', data, GQ_GENERATE_PARENT_TOKEN);
    }


    sentAdminPasswordResetOTP(data) {
        return request('POST', data, GQ_SENT_ADMIN_PASSWORD_RESET_OTP);
    }

    verifyAdminForgotEmail(data) {
        return request('POST', data, GQ_VERIFY_ADMIN_FORGOT_EMAIL);
    }

    resetPassword(data) {
        return request('POST', data, GQ_RESET_PASSWORD);
    }

    generateParentPassword(data) {
        return request('POST', data, GQ_GENERATE_PARENT_PASSWORD);
    }

    getParentSiteFaq(data){
        return request('POST', data, GQ_GET_ALL_PARENT_SITE_FAQ)
    }

    getParentFaqCategory(){
        return request('POST', {}, GQ_GET_PARENT_FAQ_CATEGORY)
    }

    //Children API's
    addChild(data) {
        return request('POST', data, GQ_CREATE_NEW_CHILD);
    }

    updateBasicInfo(data) {
        return request('POST', data, GQ_UPDATE_BASIC_INFO);
    }

    updateEducationalInfo(data) {
        return request('POST', data, GQ_UPDATE_EDUCATIONAL_INFO);
    }

    getChildList() {
        return request('POST', {}, GQ_GET_CHILD);
    }

    getChildByParent(data) {
        return request('POST', data, GQ_GET_CHILD_BY_PARENT);
    }

    getChildByParentNew(data) {
        return request('POST', data, GQ_GET_CHILD_BY_PARENT_NEW);
    }
    updateChildrenNew(data) {
        return request('POST', data, GQ_GET_CHILD_UPDATE);
    }

    singleChildDetail(data) {
        return request('POST', data, GQ_GET_SINGLE_CHILD_DETAIL);
    }

    getDimensionAnsweredQuestion(data) {
        return request('POST', data, GQ_GET_DIMENSION_ANSWERED_QUESTION);
    }

    getAllAdvisoryCallForChild(data) {
        return request('POST', data, GQ_GET_CHILD_ALL_ADVISORY_CALL);
    }

    getPolygonAnalysisReport(data){
        return request('POST', data, GQ_GET_POLYGON_ANALYSIS_REPORT);
    }

    getTDPReport(data){
        return request('POST', data, GQ_GET_TDP_REPORT);
    }

    getMultiDimensionCognitiveReport(data){
        return request('POST', data, GET_MULTI_DIMENSION_COGNITIVE_REPORT);
    }

    getOverlayReport(){
        return request('POST', {}, GQ_GET_OVERVIEW_TEMPLATE);
    }


    getDimensionCognitiveReport(data){
        return request('POST', data, GQ_GET_DIMENSION_COGNITIVE_REPORT);
    }

    getChildTotalEarnedCoins(data){
        return request('POST', data, GET_CHILD_TOTAL_EARNED_COINS);
    }


    uploadImage(data) {
        return request('POST', data, GQ_IMAGE_UPLOAD, true);
    }

    UploadPaymentScreenshot(data){
        return request('POST', data, GQ_IMAGE_UPLOAD_ALREADY_PAID, true);
    }

    uploadFile(data) {
        return request('POST', data, GQ_UPLOAD_FILE);
    }

    getSubjects(data) {
        return request('POST', data, GQ_GET_SUBJECTS);
    }

    updateSubject(data) {
        return request('POST', data, GQ_UPDATE_SUBJECTS);
    }

    getSkills(data) {

        return request('POST', data, GQ_GET_SKILLS);
    }

    updateSkills(data) {
        return request('POST', data, GQ_UPDATE_SKILLS);
    }

    getHabits(data) {
        return request('POST', data, GQ_GET_HABITS);
    }

    getCourses(data) {
        return request('POST', data, GQ_GET_COURSES);
    }

    getElasticSearchCourses(data) {
        return request('POST', data, GQ_GET_ELASTIC_SEARCH_COURSES);
    }

    providerBulkInsert(data) {
        return request('POST', data, GQ_PROVIDER_BULK_INSERT);
    }

    getSingleCourseDetail(data) {
        return request('POST', data, GQ_SINGLE_COURSE_DETAIL)
    }

    updateHabits(data) {
        return request('POST', data, GQ_UPDATE_HABITS);
    }

    getWishlistData(data) {
        return request('POST', data, GQ_GET_WISHLIST_COURSES);
    }

    addCourseWishlist(data) {
        return request('POST', data, GQ_ADD_COURSE_TO_WISHLIST);
    }

    removeCourseWishlist(data) {
        return request('POST', data, GQ_REMOVE_COURSE_FROM_WISHLIST);
    }

    getCourseReviews(data) {
        return request('POST', data, GQ_GET_COURSE_REVIEWS);
    }

    getLearningNeeds(){
        return request('POST', {}, GQ_GET_LEARNING_NEEDS);
    }

    getUniqueTalents(){
        return request('POST', {}, GQ_GET_TALENT_NEEDS);
    }

    getTraitsMapping(){
        return request('POST', {}, GQ_GET_TRAITS_MAPPING);
    }


    getFilterCareers(){
        return request('POST', {}, GQ_GET_CAREERS_MAPPING);
    }


    getSchoolData(data) {
        return request('POST', data, GQ_GET_SCHOOL);
    }

    getAvatarData() {
        return request('POST', {}, GQ_GET_AVATAR);
    }

    getInterestData(data) {
        return request('POST', data, GQ_GET_INTEREST);
    }

    getOnlineCourseData(data) {
        return request('POST', data, GQ_GET_ONLINE_COURSE);
    }

    getHobbiesData(data) {
        return request('POST', data, GQ_GET_HOBBIES);
    }

    getAllInterestData() {
        return request('POST', {}, GQ_GET_ALL_INTEREST);
    }

    getAllOnlineCourseData() {
        return request('POST', {}, GQ_GET_ALL_ONLINE_COURSE);
    }

    deleteOnlineCourse(data) {
        return request('POST', data, GQ_DELETE_ONLINE_COURSE);
    }

    deleteProgramType(data) {
        return request('POST', data, GQ_DELETE_PROGRAM_TYPE);
    }

    approveProgramType(data) {
        return request('POST', data, GQ_APPROVE_PROGRAM_TYPE);
    }

    getAllHobbiesData() {
        return request('POST', {}, GQ_GET_ALL_HOBBIES);
    }

    getAllSchoolData() {
        return request('POST', {}, GQ_GET_ALL_SCHOOL);
    }

    addNewSchool(data) {
        return request('POST', data, GQ_ADD_SCHOOL);
    }

    addOnlineCourse(data) {
        return request('POST', data, GQ_ADD_ONLINE_COURSE);
    }

    addNewInterest(data) {
        return request('POST', data, GQ_ADD_INTEREST);
    }

    addNewHobby(data) {
        return request('POST', data, GQ_ADD_HOBBY);
    }

    addNewHabit(data) {
        return request('POST', data, GQ_ADD_HABIT);
    }

    searchHabit(data) {
        return request('POST', data, GQ_SEARCH_HABIT);
    }


    //Admin End API's
    updateSectionordering(data){
        return request('POST',data,GQ_UPDATE_SECTION_ORDERING);
    }

    getSelectedEaRegimePreference(){
        return request('POST',{},GQ_GET_SELECTED_EA_REGIME_PREFERENCE);
    }

    updateEaRegimePreference(data){
        return request('POST',data,GQ_UPDATE_EA_REGIME_PREFERENCE);
    }

    getFieldsGroup(){
        return request('POST',{},GQ_GET_FIELDS_GROUP);
    }

    getAllUsers(data) {
        return request('POST', data, GQ_GET_USERS);
    }

    getAllTeacherAssignedStudents(data) {
        return request('POST', data, GQ_GET_ALL_TEACHER_ASSIGNED_STUDENTS);
    }

    getAllResetEmail(data) {
        return request('POST', data, GQ_GET_RESET_EMAIL);
    }
    getDeleteChild(data) {
        return request('POST', data, GQ_GET_Delete_Child);
    }
    getAllSchoolUsers() {
        return request('POST', {}, GQ_GET_ALL_SCHOOL_USERS);
    }

    updateSchoolSession(data) {
        return request('POST', data, GQ_UPDATE_SCHOOL_SESSION);
    }

    inviteNewStudent(data) {
        return request('POST', data, GQ_INVITE_NEW_STUDENT);
    }


    sendStudentReminder(data) {
        return request('POST', data, GQ_SEND_STUDENT_REMINDER);
    }

    allowChildReportAccess(data) {
        return request('POST', data, GQ_ALLOW_CHILD_REPORT_ACCESS);
    }

    generatePdfReportId(data) {
        return request('POST', data, GQ_GENERATE_PDF_REPORT_ID);
    }

    getAllProvidersWithBusiness() {
        return request('POST', {}, GQ_GET_ALL_PROVIDERS_WITH_BUSINESS);
    }

    getAllChildren(data) {
        return request('POST', data, GQ_GET_ALL_CHILDREN);
    }
    getChildManageSlots(data) {
        return request('POST', data, GET_CHILD_MANAGE_SLOTS);
    }
    GetTalentGuidanceUser(data) {
        return request('POST', data, GET_TALENT_GUIDANCE);
    }
    
    GetAllAdvisoryCallUsers(data) {
        return request('POST', data, GET_FREE_ADIVISORY_CALL);
    }
    getAllCounsellingUsers(data) {
        return request('POST', data, GET_COUNSELING_CALL);
    }

    getAllCoupons(data) {
        return request('POST', data, GET_COUPUNS);
    }

    getAllReferralLinks() {
        return request('POST', {}, GET_ALL_REFERRRAL_LINKS);
    }

    getAllPaymentLinks(data) {
        return request('POST', data, GET_ALL_PAYMENT_LINK);
    }

    getAllSchoolEnquiries(data) {
        return request('POST', data, GET_ALL_SCHOOL_ENQIRY);
    }

    
    getExportDataHistory(data) {
        return request('POST', data, GET_EXPORT_DATA);
    }



    AddCoupon(data) {
        return request('POST', data, GQ_APPLYCOPUN);
    }
    GetAllFreeAssessmentUser(data) {
        return request('POST', data, GET_FREE_ASSESSMENT_USERS);
    }
    getAllRegDropouts(data) {
        return request('POST', data, GET_REG_DROPOUT_USERS);
    }

    GetContactDetails(data) {
        return request('POST', data, GET_CONTACT_US);
    }

    GetPaymentTracker(data) {
        return request('POST', data, GET_PAYMENT_TRACKER);
    }

    getChildrenFreeSlots(data) {
        return request('POST', data, GET_CHILDREN_FREE_SLOTS);
    }
    getTalentGuidanceSlots(data) {
        return request('POST', data, GET_CHILDREN_TALENT_GUIDANCE_SLOTS);
    }

    getChildrenBookingRequest(data) {
        return request('POST', data, GET_CHILDREN_BOOKING_REQUEST);
    }

    createCourseWithAllInfo(data) {
        return request('POST', data, GQ_CREATE_COURSE_WITH_ALL_INFO);
    }

    editCourseWithAllInfo(data) {
        return request('POST', data, GQ_EDIT_COURSE_WITH_ALL_INFO);
    }

    getCourseList(data) {
        return request('POST', data, GQ_GET_ALL_COURSES);
    }

    createCourseAttribute(data) {
        return request('POST', data, GQ_CREATE_COURSE_ATTRIBUTE);
    }

    getCourseAttributes(data) {
        return request('POST', data, GQ_GET_COURSE_ATTRIBUTES);
    }

    enableDisableCourse(data) {
        return request('POST', data, GQ_ENABLE_DISABLE_COURSE);
    }

    getSingleUserDetail(data) {
        return request('POST', data, GQ_GET_SINGLE_USER_DETAIL);
    }

    getProviderEnquiries(data) {
        return request('POST', data, GQ_GET_PROVIDER_ENQUIRIES);
    }

    chanageProviderStatus(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_STATUS)
    }

    getParentEarlyAccess(data){
        return request('POST', data, GQ_GET_PARENT_EARLY_ACCESS)
    }

    generateSingleLogin(data){
        return request('POST', data, GQ_GENERATE_SINGLE_LOGIN)
    }


    generateBulkLogin(data){
        return request('POST', data, GQ_GENERATE_BULK_LOGIN)
    }

    sendEmailPhoneVerification(data){
        return request('POST', data, GQ_SEND_EMAIL_PHONE_VERIFICATION)
    }

    exportEarlyAccessUsers(data){
        return request('POST', data, GQ_EXPORT_EARLY_ACCESS_USERS)
    }

    exportNoChildUsers(data){
        return request('POST', data, GQ_EXPORT_NO_CHILD_USERS)
    }

    exportCompleteTestUsers(data){
        return request('POST', data, GQ_EXPORT_COMPLETE_CHILD_USERS)
    }

    exportAssessmentComReport(data){
        return request('POST', data, GQ_EXPORT_ASSESSMENT_COM_REPORT)
    }


    exportInCompleteTestUsers(data){
        return request('POST', data, GQ_EXPORT_INCOMPLETE_CHILD_USERS)
    }



    GQ_EXPORT_INCOMPLETE_CHILD_USERS

    exportTDAPaymentUsers(data){
        return request('POST', data, GQ_EXPORT_PAYMENT_TRACKER_USERS)
    }

    markUserAsArchived(data){
        return request('POST', data, GQ_MARK_USER_AS_ARCHIVED)
    }

    deleteArchivedUser(data){
        return request('POST', data, GQ_DELETE_ARCHIVED_USER)
    }

    archivedUsers(data){
        return request('POST', data, GQ_ARCHIVED_USERS)
    }

    deletedUsers(data){
        return request('POST', data, GQ_DELETED_USERS)
    }

    getAdminActivityLogs(data){
        return request('POST', data, GQ_ADMIN_ACTIVITY_LOGS)
    }

    getGroupAttributes(){
        return request('POST', {}, GQ_FETCH_GROUP_ATTRIBUTES)
    }
    getfieldsGroup(){
        return request('POST', {}, GET_FIELD_GROUP)
    }

    createChildAttribute(data){
        return request('POST', data, GQ_CREATE_CHILD_ATTRIBUTE)
    }

    updateSectionInformation(data){
        return request('POST', data, GQ_UPDATE_SECTION_INFORMATION)
    }

    updateSectionFieldInformation(data){
        return request('POST', data, GQ_UPDATE_SECTION_FIELD_INFORMATION)
    }

    updateChildSingleAttribute(data){
        return request('POST', data, GQ_UPDATE_CHILD_SINGLE_ATTRIBUTE)
    }

    updateSchoolSingleAttribute(data){
        return request('POST', data, GQ_UPDATE_SCHOOL_SINGLE_ATTRIBUTE)
    }


    updateChildAttribute(data){
        return request('POST', data, GQ_UPDATE_CHILD_ATTRIBUTE)
    }

    singleAttributeDetails(data){
        return request('POST', data, GQ_SINGLE_ATTRIBUTE_DETAILS)
    }

    deleteProviderCategory(data){
        return request('POST', data, GQ_DELETE_PROVIDER_CATEGORY)
    }

    deleteChildAttribute(data){
        return request('POST', data, GQ_DELETE_CHILD_ATTRIBUTE)
    }

    deleteChildAttributeById(data){
        return request('POST', data, GQ_DELETE_CHILD_ATTRIBUTE_BY_ID)
    }

    deleteSchoolAttributeById(data){
        return request('POST', data, GQ_DELETE_SCHOOL_ATTRIBUTE_BY_ID)
    }

    updateParentExpectationFearConcerns(data){
        return request('POST', data, GQ_UPDATE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID)
    }

    deleteParentExpectationFearConcerns(data){
        return request('POST', data, GQ_DELETE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID)
    }

    approveParentExpectationFearConcerns(data){
        return request('POST', data, GQ_APPROVE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID)
    }

    getParentExpectationFearConcerns(data){
        return request('POST', data, GQ_FETCH_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTES)
    }

    getOnlineCourseAttributes(data){
        return request('POST', data, GQ_FETCH_ONLINE_COURSE_ATTRIBUTES)
    }

    updateOnlineCourseAttributes(data){
        return request('POST', data, GQ_UPDATE_ONLINE_COURSE_ATTRIBUTES)
    }

    deleteOnlineCourseAttributes(data){
        return request('POST', data, GQ_DELETE_ONLINE_COURSE_ATTRIBUTES)
    }


    approveOnlineCourseAttributes(data){
        return request('POST', data, GQ_APPROVE_ONLINE_COURSE_ATTRIBUTES)
    }



    approveChildAttributeById(data){
        return request('POST', data, GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID)
    }

    approveSchoolAttributeById(data){
        return request('POST', data, GQ_APPROVE_SCHOOL_ATTRIBUTE_BY_ID)
    }

    approveProviderCategory(data){
        return request('POST', data, GQ_APPROVE_PROVIDER_CATEGORY)
    }

    addFaqCategory(data){
        return request('POST', data, GQ_ADD_FAQ_CATEGORY)
    }

    editFaqCategory(data){
        return request('POST', data, GQ_EDIT_FAQ_CATEGORY)
    }

    deleteFaqCategory(data){
        console.log(data)
        return request('POST', data, GQ_DELETE_FAQ_CATEGORY)
    }

    addSiteFaq(data){
        return request('POST', data, GQ_ADD_SITE_FAQ)
    }

    editSiteFaq(data){
        return request('POST', data, GQ_EDIT_SITE_FAQ)
    }

    deleteSiteFaq(data){
        return request('POST', data, GQ_DELETE_SITE_FAQ)
    }

    getSiteFaq(data){
        return request('POST', data, GQ_GET_ALL_SITE_FAQ)
    }

    getFaqCategory(){
        return request('POST', {}, GQ_GET_FAQ_CATEGORY)
    }

    getAllFeedbacks(data){
        return request('POST', data, GQ_GET_ALL_FEEDBACK)
    }

    getLdPercentageContribution(){
        return request('POST', {}, GQ_GET_LD_PERCENTAGE_CONTRIBUTION)
    }

    updateLdContribution(data){
        return request('POST', data, GQ_UPDATE_LD_CONTRIBUTION)
    }

    getAllReportedReview(data){
        return request('POST', data, GQ_GET_ALL_REPORTED_REVIEW)
    }

    addSiteFaqBulk(data){
        return request('POST', data, GQ_ADD_SITE_FAQ_BULK,true)
    }

    scheduleChildrenRequest(data){
        return request('POST', data, GQ_SCHEDULE_CHILDREN_REQUEST)
    }

    scheduleDemoChildrenRequest(data){
        return request('POST', data, GQ_SCHEDULE_DEMO_CHILDREN_REQUEST)
    }

    sessionProviderEmail(data){
        return request('POST', data, GQ_SESSION_PROVIDER_EMAIL)
    }

    editScheduleChildrenRequest(data){
        return request('POST', data, GQ_EDIT_SCHEDULE_CHILDREN_REQUEST)
    }

    getDemoReviewsByRequestId(data){
        return request('POST', data, GQ_GET_DEMO_REVIEW_BY_REQUEST_ID)
    }

    sendMarketingEmail(data){
        return request('POST', data, GQ_SEND_MARKETTING_EMAIL)
    }

    addEmailTemplate(data){
        return request('POST', data, GQ_ADD_TEMPLATE_EMAIL)
    }
    editEmailTemplate(data){
        return request('POST', data, GQ_EDIT_TEMPLATE_EMAIL)
    }
    deleteEmailTemplate(data){
        return request('POST', data, GQ_DELETE_TEMPLATE_EMAIL)
    }
    getAllEmailTemplate(){
        return request('POST', {}, GQ_GET_ALL_EMAIL_TEMPLATES)
    }
    getAllEmailTemplateTypes(){
        return request('POST', {}, GQ_GET_ALL_EMAIL_TEMPLATES_TYPES)
    }

    addEALandingPage(data){
        return request('POST', data, GQ_CREATE_EA_LANDING_PAGE)
    }
    editEALandingPage(data){
        return request('POST', data, GQ_EDIT_EA_LANDING_PAGE)
    }
    deleteEALandingPage(data){
        return request('POST', data, GQ_DELETE_EA_LANDING_PAGE_DETAILS)
    }

    getListingEAPages(){
        return request('POST', {}, GQ_GET_ALL_EA_LANDING_LIST)
    }

    getListingOfEAPageIcons(){
        return request('POST', {}, GQ_GET_ALL_EA_LANDING_ICON_LIST)
    }


    getAllMarketingEmails(){
        return request('POST', {}, GQ_GET_ALL_MARKETING_EMAILS)
    }

    getUserGroup(data){
        return request('POST', data, GQ_GET_USER_GROUP)
    }

    deleteUserFromGroup(data){
        return request('POST', data, GQ_DELETE_USER_FROM_GROUP)
    }

    getGroupById(data){
        return request('POST', data, GQ_GET_GROUP_BY_ID)
    }

    updateGroupData(data){
        return request('POST', data, GQ_UPDATE_GROUP_DATA)
    }

    deleteChildGroup(data){
        return request('POST', data, GQ_DELETE_GROUP)
    }

    //Provider
    createProviderEnquiry(data) {
        return request('POST', data, GQ_CREATE_PROVIDER_ENQUIRY);
    }

    completeProviderProfile(data, type) {
        return request('POST', data, type === 1 ? GQ_COMPLETE_PROVIDER_PROFILE : GQ_UPDATE_PROVIDER_PROFILE);
    }

    getProviderProfile() {
        return request('POST', {}, GQ_GET_PROVIDER_PROFILE);
    }

    updateCompanyDetails(data) {
        return request('POST', data, GQ_UPDATE_COMPANY_DETAILS);
    }

    searchProviderCategory(data) {
        return request('POST', data, GQ_SEARCH_PROVIDER_CATEGORY);
    }

    addNewProviderCategory(data) {
        return request('POST', data, GQ_ADD_NEW_CATEGORY);
    }

    updateProviderCategory(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_CATEGORY);
    }

    getProgramTypeData() {
        return request('POST', {}, GQ_GET_PROGRAM_TYPE);
    }

    getProgramTypeDataAdmin() {
        return request('POST', {}, GQ_GET_PROGRAM_TYPE_ADMIN);
    }

    addProgramTypeData(data) {
        return request('POST', data, GQ_ADD_PROGRAM_TYPE);
    }

    searchProgramTypeData(data) {
        return request('POST', data, GQ_SEARCH_PROGRAM_TYPE);
    }

    updateProviderProgramType(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_PROGRAM_TYPE);
    }

    updateContactPerson(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_CONTACT_INFO);
    }

    verifyBusinessEmail(data) {
        return request('POST', data, GQ_VERIFY_BUSINESS_EMAIL);
    }

    verifyBusinessPhone(data) {
        return request('POST', data, GQ_VERIFY_BUSINESS_PHONE);
    }

    sendBusinessEmailOTP(data) {
        return request('POST', data, GQ_SEND_OTP_BUSINESS_EMAIL);
    }

    sendBusinessPhoneOTP(data) {
        return request('POST', data, GQ_SEND_OTP_BUSINESS_PHONE);
    }

    updateProviderBankDetails(data) {
        return request('POST', data, GQ_UPDATE_BANK_DETAILS);
    }

    updateCorporateDetails(data) {
        return request('POST', data, GQ_UPDATE_CORPORATE_DETAILS);
    }


    updateProviderDocument(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_DOCUMENT);
    }

    getCoursesByProvider() {
        return request('POST', {}, GQ_GET_COURSES_BY_PROVIDER);
    }


    getAllDesignation(){
        return request('POST', {}, GQ_GET_ALL_DESIGNATION);
    }

    searchDesignation(data){
        return request('POST', data, GQ_SEARCH_DESIGNATION);
    }

    addNewDesignation(data){
        return request('POST', data, GQ_ADD_NEW_DESIGNATION);
    }

    //Common
    getCategories() {
        return request('POST', {}, GQ_GET_PROVIDER_CATEGORIES);
    }

    //Common
    getAdminCategories() {
        return request('POST', {}, GQ_GET_PROVIDER_CATEGORIES_ADMIN);
    }

    getAllCategories() {
        return request('POST', {}, GQ_GET_CATEGORIES);
    }

    // ADD COURSE
    createOrUpdateCourse(mutation, data) {
        return request('POST', data, mutation);
    }

    getSingleCourse(data) {
        return request('POST', data, GQ_GET_COURSE);
    }

    getCourseCharges() {
        return request('POST', {}, GQ_COURSE_CHARGES);
    }

    setMediaLog(data) {
        return request('POST', data, GQ_SET_MEDIA_LOG);
    }

    getStoredMediaByType(data) {
        return request('POST', data, GQ_GET_STORED_MEDIA_BY_TYPE);
    }

    updateProviderLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_LOGO_BANNER);
    }

    updateChildLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_CHILD_IMAGE_BANNER);
    }

    updateChildProfile(data, mutation) {
        return request('POST', data, mutation);
    }

    getAllOtherCourseProviders() {
        return request('POST', {}, GQ_SEARCH_COURSE_PROVIDERS);
    }

    getOnlineCoursesByChild(data) {
        return request('POST', data, GQ_GET_ALL_ONLINE_COURSES_BY_CHILD);
    }

    addOnlineCourseProvider(data) {
        return request('POST', data, GQ_ADD_ONLINE_COURSE_PROVIDER);
    }

    getGlobalChildAttributes(data) {
        return request('POST', data, GQ_GET_GLOBAL_CHILD_ATTRIBUTES);
    }

    getGlobalChildAttributesByType(data) {
        return request('POST', data, GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE);
    }

    addGlobalChildAttributes(data) {
        return request('POST', data, GQ_ADD_CHILD_GLOBAL_ATTRIBUTE);
    }

    getChildAssignedAttributes(data) {
        return request('POST', data, GQ_GET_CHILD_ASSIGNED_ATTRIBUTES);
    }

    getChildSchools(data) {
        return request('POST', data, GQ_GET_CHILD_SCHOOLS);
    }

    getChildGradeSubjects(data) {
        return request('POST', data, GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD);
    }

    getChildReportCards(data) {
        return request('POST', data, GQ_GET_CHILD_REPORT_CARDS);
    }

    getChildCertificates(data) {
        return request('POST', data, GQ_GET_CHILD_CERTIFICATES);
    }

    getChildCourses(data) {
        return request('POST', data, GQ_GET_CHILD_ONGOING_COURSES);
    }

    getChildDemoCourses(data) {
        return request('POST', data, GQ_GET_CHILD_DEMO_COURSES);
    }

    getChildLikedCourses(data) {
        return request('POST', data, GQ_GET_CHILD_LIKED_COURSES);
    }

    // COURSES API ENDPOINTS
    updateCourseLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_COURSE_LOGO_BANNER);
    }

    getCommunicationModes() {
        return request('POST', {}, GQ_GET_COMMUNICATION_MODES);
    }

    searchCommunicationModes(data) {
        return request('POST', data, GQ_GET_COMMUNICATION_MODES);
    }

    addCommunicationModes(data) {
        return request('POST', data, GQ_ADD_COMMUNICATION_MODES);
    }

    getCourseMedias(data) {
        return request('POST', data, GQ_GET_COURSE_MEDIA);
    }

    

    createCourses(data) {
        return request('POST', data, GQ_CREATE_COURSE);
    }

    getCommonData(query) {
        return request('POST', {}, query);
    }

    sendNotification(data){
        return request('POST', data, GQ_ADMIN_SEND_NOTIFICATION);
    }

    sendEmail(data){
        return request('POST', data, GQ_ADMIN_SEND_EMAIL);
    }

    addGroup(data){
        return request('POST', data, GQ_ADMIN_ADD_GROUP);
    }

    getAllNotifications(){
        return request('POST', {}, GQ_GET_ALL_NOTIFICATIONS);
    }

    getGroupNotificationsEmails(data){
        return request('POST', data, GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS);
    }

    updateBulletPointIconImage(data) {
        return request('POST', data, GQ_UPDATE_BULLET_POINT_ICONS)
    }


    //Weightage
    getAttributeWeight(data){
        return request('POST', data, GQ_GET_ATTRIBUTE_WEIGHTAGE);
    }

    getFamilyAttributeWeight(data){
        return request('POST', data, GQ_GET_FAMILY_ATRRIBUTE);
    }

    familyAttributeDetails(data){
        return request('POST', data, GQ_GET_FAMILY_ATTRIBUTE_DETAILS);
    }

    updateAttributeWeight(data){
        return request('POST', data, GQ_UPDATE_ATTRIBUTE_WEIGHTAGE);
    }

    // Sub Admin
    createSubAdmin(data){
        return request('POST', data, GQ_CREATE_SUB_ADMIN);
    }

    deleteSubAdmin(data){
        return request('POST', data, GQ_DELETE_SUB_ADMIN);
    }

    updateSubAdminPermissions(data){
        return request('POST', data, GQ_UPDATE_SUB_ADMIN_PERMISSIONS);
    }

    getSubAdminById(data){
        return request('POST', data, GQ_GET_SUB_ADMIN_BY_ID);
    }

    getAllSubAdmins(){
        return request('POST', {}, GQ_GET_ALL_SUB_ADMINS);
    }

    //Celebrity
    getAllCelebrity(data){
        return request('POST', data, GQ_GET_ALL_CELEBRITY);
    }

    CreateCelebrity(data){
        return request('POST', data, GQ_CREATE_CELEBRITY);
    }

    updateCelebrity(data){
        return request('POST', data, GQ_UPDATE_CELEBRITY);
    }

    deleteCelebrity(data){
        return request('POST', data, GQ_DELETE_CELEBRITY);
    }

    UpdateTalentAdvisoryCallDetails(data) {
        return request('POST', data, GQ_UPDATE_TALENT_DISCOVERY_DATA);
    }

    updateCounsellingCallDetails(data) {
        return request('POST', data, GQ_UPDATE_COUNSELLING_DATA);
    }

    updateTalentGuidanceSession(data) {
        return request('POST', data, GQ_UPDATE_TALENT_GUIDANCE_STATUS);
    }

    createCoupon(data) {
        return request('POST', data, GQ_CREATE_COPUN);
    }

    CreateTDAPaymentUser(data) {
        return request('POST', data, GQ_TDA_PAYEMENTUSER);
    }


    checkExistingUser(data) {
        return request('POST', data, GQ_CHECKEXISTINGUSER);
    }

    UpdateBillingAddressTDA(data) {
        return request('POST', data, GQ_UPDATE_BILING_INFO);
    }

    UpdateBillingInfo(data) {
        return request('POST', data, GQ_UPDATE_BILING_INFO2);
    }


    checkPaymentStatus(data) {
        return request('POST', data, GQ_PAYMENT_STATUS);
    }

    adminOrder (data) {
        return request('POST', data, GQ_CREATEADMINORDER);
    }

    adminOrdertda (data) {
        return request('POST', data, GQ_ADMIN_ORDER_TDA);
    }

    createAffiliates (data) {
        return request('POST', data, GQ_ADMIN_CREATE_AFFILIATES);
    }

    editCoupon(data) {
        return request('POST', data, GQ_EDIT_COPUN);
    }


    deleteCoupon(data) {
        return request('POST', data, GQ_DELETE_COPUN);
    }

    UpdateContactUs(data) {
        return request('POST', data, GQ_UPDATE_CONTACT_US);
    }

    getTotalUsers(data) {
        return request('POST', data, GQ_TOTAL_USERS);
    }

    getTotalPaidUsers(data) {
        return request('POST', data, GQ_TOTAL_PAID_USERS);
    }
    getRecentPayments(data) {
        return request('POST', data, GQ_RECENT_PAYMENTS);
    }


    getTotalCollectedPayment(data) {
        return request('POST', data, GQ_TOTAL_PAYMENT_COLLECTED);
    }

    getTotalReportsGenerated(data) {
        return request('POST', data, GQ_TOTAL_REPORT_GENERATED);
    }
    

    accessNowAdmin(data) {
        return request('POST', data, GQ_ACCESS_NOW_ADMIN);
    }


    DownloadInvoice(data) {
        return request('POST', data, GQ_DOWNLOAD_INVOICE);
    }
    

    GetDeletedUserSurvey(data) {
        return request('POST', data, GQ_DELETED_USERS_SURVEY);
    }

    UpdatePunchingDetails(data) {
        return request('POST', data, GQ_UPDATE_PUNCHING_DETAILS);
    }


    GetTotalTACusers(data) {
        return request('POST', data, GQ_GET_TOTAL_TAC_USERS);
    }

    
    GetTotalPaymentLinkGenerated(data) {
        return request('POST', data, GQ_GET_TOTAL_PAYMENT_LINK_GENERATED);
    }
    
    MarkAsTestingPayment(data) {
        return request('POST', data, GQ_MARK_AS_TESTING_PAYMENT);
    }

    GetTotalChildren(data) {
        return request('POST', data, GQ_GET_TOTAL_CHILDREN);
    }

    GetPremiumReportGenerated(data) {
        return request('POST', data, GQ_GET_PREMIUM_REPORT_GENERTAED);
    }


    
    UpdateChildReportStatus(data) {
        return request('POST', data, GQ_UPDATE_CHILD_REPORT_STATUS);
    }

    ExportDownloadReportData(data) {
        return request('POST', data, GQ_EXPORT_DOWNLOAD_REPORT_DATA);
    }
    getAllAffiliates(data) {
        return request('POST', data, GQ_EXPORT_GET_ALL_AFFILLIATES);
    }
    RequestChildAssessmentInfo(data) {
        return request('POST', data, GQ_CHILD_ASSESSMENT_INFO_DATA);
    }



    SaveAlreadyPaidPaymentDetails(data){
        return request('POST', data, GQ_SAVE_ALREADY_PAID_PAYMENT_DETAILS);
    }
    

    deleteAffiliate(data){
        return request('POST', data, GQ_ADMIN_DELETE_AFFILIATES);
    }


    
    inactiveAffiliate(data){
        return request('POST', data, GQ_ADMIN_INACTIVE_AFFILIATES);
    }
      
    editAffiliate(data){
        return request('POST', data, GQ_ADMIN_EDIT_AFFILIATES);
    }


    
    
    
   
}
