import {LAYOUT, MENU_BEHAVIOUR, MENU_PLACEMENT, NAV_COLOR, RADIUS, THEME_COLOR} from 'constants.js';

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
    defaultTitle: 'Joonify Admin',
    titleTemplate: '%s | Joonify Admin',
};

export const DEFAULT_PATHS = {
    APP: '/',
    LOGIN: '/login',
    PARENT_EMAIL_VERIFIED: '/email-verification',
    VERIFY_MOBILE: '/verify-mobile',
    REGISTER: '/register',
    PROVIDER_REGISTER: '/provider-register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    USER_WELCOME: '/dashboards/default',
    NOTFOUND: '/login',
    UNAUTHORIZED: '/unauthorized',
    INVALID_ACCESS: '/invalid-access',
    PROVIDER_EMAIL_VERIFICATION: '/provider-email-verified',
};

export const DEFAULT_SETTINGS = {
    MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
    MENU_BEHAVIOUR: MENU_BEHAVIOUR.Unpinned,
    LAYOUT: LAYOUT.Boxed,
    RADIUS: RADIUS.Rounded,
    COLOR: THEME_COLOR.LightPurple,
    NAV_COLOR: NAV_COLOR.Default,
    USE_SIDEBAR: false,
};

export const DEFAULT_USER = {
    id: 1,
    name: 'Admin',
    thumb: '/img/profile/profile-9.webp',
    role: "user",
    email: 'admin@aarklearnings.com',
};

export const REDUX_PERSIST_KEY = 'elearning-portal';

// const firebaseConfig = {
//     apiKey: process.env.REACT_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_FIREBASE_PROJECT_ID,
//     databaseURL: process.env.REACT_FIREBASE_STORAGE_BUCKET,
//     storageBucket: process.env.REACT_FIREBASE_MESSANGING_SENDER_ID,
//     messagingSenderId: process.env.REACT_FIREBASE_APP_ID,
//     appId: process.env.REACT_FIREBASE_DATABASE_URL
// };

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB8S0vvRNhEH1DBi6l9brCM2-PLctjiwEs",
    authDomain: "aarkl-deee2.firebaseapp.com",
    databaseURL: "https://aarkl-deee2-default-rtdb.firebaseio.com",
    projectId: "aarkl-deee2",
    storageBucket: "aarkl-deee2.appspot.com",
    messagingSenderId: "121549890965",
    appId: "1:121549890965:web:adb1c249e67011c187829c"
};

export default firebaseConfig;
