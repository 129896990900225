export const GQ_GET_COURSE = `query Query($courseId: ID) {
  course(id: $courseId) {
    id
    user{
        first_name
        last_name
    }
    headline
    name
    description
    banner
    image
    category {
        id
        category
    }
    fees_type
    fees
    net_receivable
    contact_first_name
    contact_email
    contact_phone
    contact_last_name
    communication_mode
    contact_designation
    course_url
    min_age_of_student
    max_age_of_student
    grade_of_student
    school_board
    potential_of_child
    state_board_name
    student_percentage
    metality_type
    progressive_level
    parental_supervision
    faq {
      answer
      question
    }
    duration
    duration_type
    media_files {
      mimetype
      path
    }
    course_hours
    course_sessions
    course_levels
    levels
    course_level_data {
        name
        description
        hours
        sessions
    }
    course_type
    type_of_delivery
    course_conduction
    child_development
    extra_curricular
    study_material
    study_files
    diy_kits
    diy_files
    diy_urls
    session_count
    type_of_course
    group_size
    min_students
    max_students
    is_certificate_course
    lms_type
    class_medium
    payment_type
    country
    status
    refund_policy
    kids_enrolled
    class_medium_other
    payment_type_other
    createdAt
  }
}`;

export const GQ_GET_COMMUNICATION_MODES = `query GetCommunicationModes($query: String) {
  getCommunicationModes(query: $query) {
    id
    name
  }
}`;

export const GQ_ADD_COMMUNICATION_MODES = `mutation AddCommunication($name: String) {
  addCommunication(name: $name) {
    id
    name
  }
}`;

export const GQ_COURSE_FORM_1 = `mutation Mutation($course: CourseDetailsinput) {
  createCourse(course: $course) {
    id
  }
}`;

export const GQ_COURSE_FORM_2 = `mutation Mutation($course: CourseManagerInput) {
  updateCourseManagerDetail(course: $course) {
    id
  }
}`;

export const GQ_COURSE_FORM_3 = `mutation UpdateCourseMedia($course: CourseMediaInput) {
  updateCourseMedia(course: $course) {
    id
  }
}`;

export const GQ_COURSE_FORM_4 = `mutation Mutation($course: CourseEligibilityInput) {
  updateCourseEligibilty(course: $course) {
    id
  }
}`;

export const GQ_COURSE_FORM_5 = `mutation UpdateCourseFAQ($course: CourseFaqInput) {
  updateCourseFAQ(course: $course) {
    id
  }
}`;

export const GQ_COURSE_FORM_6 = `mutation Mutation($course: CourseScheduleInput) {
  updateCourseSchedule(course: $course) {
    id
  }
}`;

export const GQ_COURSE_CHARGES = `query GetCourseAdditionalFees {
  getCourseAdditionalFees {
    tax
    portalCharges
  }
}`;

export const GQ_UPDATE_COURSE_LOGO_BANNER = `mutation Mutation($course: CourseBannerUpdate) {
  updateCourseBannerAndImage(course: $course) {
    banner
    image
  }
}`;

export const GQ_UPDATE_COURSE_BASIC_INFO = `mutation UpdateCourseBasicDetails($course: CourseBasicUpdate) {
  updateCourseBasicDetails(course: $course) {
    id
  }
}`;

export const GQ_UPDATE_COURSE_COMMUNICATION = `mutation UpdateCourseCommunication($course: CourseCommunicationUpdate) {
  updateCourseCommunication(course: $course) {
    id
  }
}`;

export const GQ_CREATE_COURSE = `mutation CreateCourseByName($course: CreateCourseInput) {
  createCourseByName(course: $course) {
    id
  }
}`;

export const GQ_GET_COURSE_MEDIA = `query CourseMedias($courseMediasId: String) {
  courseMedias(id: $courseMediasId) {
    id
    course
    path
    original_path
    mimetype
  }
}`;

export const GQ_DELETE_COURSE_MEDIA = `mutation DeleteCourseMedia($deleteCourseMediaId: String) {
  deleteCourseMedia(id: $deleteCourseMediaId) {
    id
  }
}`;

export const GQ_UPDATE_COURSE_MEDIA_BY_ID = `mutation UpdateCourseMediaById($course: mediaUpdate) {
  updateCourseMediaById(course: $course) {
    id
  }
}`;

export const GQ_UPDATE_COURSE_URLS = `mutation UpdateCourseURL($course: CourseURLUpdate) {
  updateCourseURL(course: $course) {
    id
  }
}`;

export const GQ_GET_ALL_SCHOOL_BOARDS = `query GetAllBoard {
  getAllBoard {
    name
  }
}`;

export const GQ_GET_ALL_PAYMENT_OPTIONS = `query GetPaymentOptions {
  getPaymentOptions {
    name
  }
}`;

export const GQ_GET_ALL_SCHEDULE_OPTIONS = `query GetScheduleOptions {
  getScheduleOptions {
    name
  }
}`;

export const GQ_GET_ALL_PARENT_SITE_FAQ = `query GetAllSiteFaqs($keywords: String, $categories: [String], $page: Int) {
  getAllSiteFaqs(keywords: $keywords, categories: $categories, page: $page) {
    faqs {
      id
      title
      descriptions
      categories
      sort_order
      createdAt
    }
    total
  }
}`;

export const GQ_GET_PARENT_FAQ_CATEGORY = `query GetSiteFaqCategories {
  getSiteFaqCategories {
    id
    name
    createdAt
  }
}`;
