export const GQ_ADD_CHILD = `mutation Mutation($child: ChildCreateInput) {
  createChild(child: $child) {
    id
  }
}`;

export const GQ_GET_CHILD = `query ChildrenByParent {
  childrenByParent {
    id
    first_name
    last_name
    date_of_birth
    image
    gender
  }
}`;

export const GQ_GET_SINGLE_CHILD_DETAIL = `query Child($childId: String) {
  child(id: $childId) {
    id
    first_name
    last_name
    school_standard
    grade12_child
    date_of_birth
    place_of_birth
    time_of_birth
    show_course_session
    about
    image
    banner
    gender
    parent{
    id 
     first_name
        last_name
    }
    city
    sleep_time{
        start
        end
    }
    wake_time{
        start
        end
    }
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
    subjects {
      subjectId
      subjectName
      score
    }
    habits {
      habitName
      habitId
    }
    skills {
      score
      skillName
      skillId
    }
    interests {
      interestId
      interestName
    }
    hobbies {
      hobbyName
      hobbyId
    }
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
    favourite_food {
      foodName
      foodId
    }
    favourite_movie {
      movieName
      movieId
    }
    favourite_music {
      musicName
      musicId
    }
    favourite_sport {
      sportId
      sportName
    }
   family_information {
      family_type
      live_with_grandparents
      father_profession
      father_company_name
      father_income
      father_profession_info
      mother_profession_info
      mother_profession
      mother_company_name
      mother_income
      child_sibling_count
      child_sibling_combination
    }
    
    expectations
    concerns
    fears
    coursesChoice
    parent_note
    child_tuition_other_subjects
    child_take_tuition
    school_name
    school_board
    state_board_name
    last_year_percent
    child_live_away
    start_month_for_hostel
    start_year_for_hostel
    end_month_for_hostel
    end_year_for_hostel
    currently_living
    psychologically_assessed
    learning_disability
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
      youtube
      other
    }

  }
}`;


export const GQ_UPDATE_BASIC_INFO = `mutation Mutation($child: ChildBasicUpdate) {
  updateBasicInfo(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_EDUCATIONAL_INFO = `mutation UpdateEducationInfo($child: ChildEducationUpdate) {
  updateEducationInfo(child: $child) {
    id
  }
}`;

export const GQ_UPLOAD_FILE = `mutation Mutation($file: String) {
      base64Upload(file: $file) {
    location
  }
}`;

export const GQ_IMAGE_UPLOAD = `mutation Mutation($file: Upload) {
  singleUpload(file: $file) {
    location
    mimetype
  }
}`;

export const GQ_IMAGE_UPLOAD_ALREADY_PAID = `mutation Mutation($file: Upload) {
  UploadPaymentScreenshot($file: Upload) 
  {
    uploadPaymentScreenshot(file: $file) {location}
  }
}
`;





export const GQ_GET_SUBJECTS = `query Query($standard: String) {
  subjectsByStandard(standard: $standard) {
    id
    name
  }
}`;

export const GQ_UPDATE_SUBJECTS = `mutation UpdateChildSubjects($child: ChildSubjectUpdate) {
  updateChildSubjects(child: $child) {
    id
  }
}`;

export const GQ_GET_SKILLS = `query Query($standard: String) {
  skillsByStandard(standard: $standard) {
    id
    name
  }
}`;

export const GQ_UPDATE_SKILLS = `mutation Mutation($child: ChildSkillUpdate) {
  updateChildSkills(child: $child) {
    id
  }
}`;

export const GQ_GET_HABITS = `query Query($standard: String) {
  habitsByStandard(standard: $standard) {
    id
    name
  }
}`;

export const GQ_UPDATE_HABITS = `mutation Mutation($child: ChildHabitUpdate) {
  updateChildHabits(child: $child) {
    id
  }
}`;

export const GQ_GET_COURSES = `query SearchCourses($params: CourseFilter) {
  searchCourses(params: $params) {
    totalPages
    courses {
      id
      provider_name
      banner {
        path
        mimetype
      }
      price
      course_levels
      name
      course_urls
      status
    }
  }
}`;

export const GQ_GET_ELASTIC_SEARCH_COURSES = `query CoursesFromElasticSearch($searchParams: searchParams, $childId: String) {
  coursesFromElasticSearch(searchParams: $searchParams, childId: $childId) {
    percentage
    rating_count
    overall_rating
    is_liked
    in_wishlist
    provider_name
    name
    banner {
      path
      mimetype
    }
    price
    tags
    course_hours
    course_length
    id
    provider_image
    skills_level
    attributes
    course_levels
    course_type
    course_sessions
    total_liked
    recommendations
    target_talent
    target_career
    target_dimensions
    apple_store_link
    google_store_link
    platform_reference
    website
    user
  }
}`;

export const GQ_SINGLE_COURSE_DETAIL = `query Course($courseId: ID, $childId: String) {
  course(id: $courseId,, childId: $childId) {
    id
    percentage
    provider_name
    provider_image
    name
    description
    banner {
      mimetype
      path
    }
    media_files {
      mimetype
      path
    }
    recommendations
    website_link
    short_description
    communication_mode
    certificate_provided
    website
    course_urls
    grade_of_student
    age_of_student
    school_board
    state_board_name
    potential_of_child
    skills_level
    attributes
    parental_supervision
    course_levels
    course_type
    course_style
    course_length
    course_hours
    course_sessions
    course_conduction
    child_mental_quotient
    study_material
    study_material_files {
      mimetype
      path
    }
    diy_kits
    diy_files {
      mimetype
      path
    }
    students_enrolled
    target_talent
    target_career
    target_dimensions
    tags
    learning_points
    price
    faq {
      answer
      question
    }
    status
    in_wishlist
    is_liked
    total_liked
    sessions
    apple_store_link
    google_store_link
    platform_reference
    createdAt
    updatedAt
    overall_rating
    overall_review
    rating_count
    user {
      id
      email
      first_name
      last_name
    }
  }
}`;

export const GQ_ADD_COURSE_TO_WISHLIST = `mutation Mutation($wishlist: WishlistInput) {
  addCourseToWishlist(wishlist: $wishlist) {
   id 
  }
}`;

export const GQ_REMOVE_COURSE_FROM_WISHLIST = `mutation Mutation($removeFromWishlistId: String) {
  removeFromWishlist(id: $removeFromWishlistId) {
    id
  }
}`;

export const GQ_GET_WISHLIST_COURSES = `query GetWishlistedCourses {
  getWishlistedCourses {
    course {
      id
      name
      description
      image
      booking_url
      price
      level
      duration
      rating
      categories
      in_wishlist
    }
  }
}`;

export const GQ_GET_ONLINE_COURSE = `query Query($query: String) {
  getOnlineCourse(query: $query) {
    id
    name
  }
}`;
export const GQ_PROVIDER_BULK_INSERT = `mutation ProviderBulkInsert($provider: ProviderBulkInput) {
  providerBulkInsert(provider: $provider) {
    user {
      id
    }
  }
}`;

export const GQ_GET_INTEREST = `query Query($query: String) {
  getInterest(query: $query) {
    id
    name
  }
}`;

export const GQ_GET_HOBBIES = `query Query($query: String) {
  getHobby(query: $query) {
    id
    name
  }
}`;

export const GQ_GET_SCHOOL = `query GetSchool($query: String) {
  getSchool(query: $query) {
    id
    name
    isApproved
    createdAt
    updatedAt
  }
}`;

export const GQ_GET_AVATAR = `query GetAllAvatar {
  getAllAvatar {
    avatar
  }
}`;

export const GQ_GET_ALL_SCHOOL = `query Query {
  getAllSchool {
    id
    name
    isApproved
    createdAt
  }
}`;

export const GQ_GET_ALL_HOBBIES = `query Query{
  getAllHobbies {
    id
    name
  }
}`;

export const GQ_GET_ALL_INTEREST = `query Query {
  getAllInterests {
    id
    name
  }
}`;

export const GQ_SEARCH_HABIT = `query Query {
  getHabit {
    id
    name
  }
}`;

export const GQ_GET_ALL_ONLINE_COURSE = `query GetAllOnlineCourses {
  getAllOnlineCourses {
    id
    name
  }
}`;

export const GQ_CREATE_NEW_CHILD = `mutation createNewChild($child: ChildInput) {
  createNewChild(child: $child) {
   first_name
   last_name
   date_of_birth
   gender
   city
   image
   id
  }
}`;

export const GQ_ADD_SCHOOL = `mutation Mutation($name: String) {
  addSchool(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_ONLINE_COURSE = `mutation Mutation($name: String) {
  addOnlineCourse(name: $name) {
    id
    name
  }
}`;
export const GQ_ADD_INTEREST = `mutation Mutation($name: String) {
  addInterest(name: $name) {
    id
    name
  }
}`;
export const GQ_ADD_HOBBY = `mutation Mutation($name: String) {
  addHobby(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_HABIT = `mutation Mutation($name: String, $standard: String) {
  addHabit(name: $name, standard: $standard) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_IMAGE_BANNER = `mutation UpdateChildMedia($child: ChildMediaUpdate) {
  updateChildMedia(child: $child) {
    id
  }
}`;
export const GQ_UPDATE_CHILD_PROFILE_INFO = `mutation UpdateChildBasicDetails($child: ChildBasicDetailsUpdate) {
  updateChildBasicDetails(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_EDUCATION_INFO = `mutation UpdateEducationInfo($child: ChildEducationUpdate) {
  updateEducationInfo(child: $child) {
    id
  }
}`;

export const GQ_SEARCH_COURSE_PROVIDERS = `query Query {
  getAllCourseProvider {
    id
    name
  }
}`;

export const GQ_GET_ALL_ONLINE_COURSES_BY_CHILD = `query GetChildEnrolledOnlineCourses($getChildEnrolledOnlineCoursesId: String) {
  getChildEnrolledOnlineCourses(id: $getChildEnrolledOnlineCoursesId) {
    child
    online_course
    course_provider
    courseEndDate
    coursePursuing
    courseStartDate
    id
  }
}`;

export const GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE = `query GetAttributesByType($filter: childFilter) {
  getAttributesByType(filter: $filter) {
    attribute_name
    type
    standard
    title
    id
  }
}`;

export const GQ_ADD_ONLINE_COURSE_PROVIDER = `mutation AddCourseProvider($name: String) {
  addCourseProvider(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_CHILD_ONLINE_COURSE = `mutation Mutation($course: EnrollCourse) {
  addEnrolledOnlineCourse(course: $course) {
    online_course
    courseStartDate
    courseEndDate
    course_provider
    coursePursuing
    child
  }
}`;

export const GQ_GET_GLOBAL_CHILD_ATTRIBUTES = `query FetchGroupedAttributes($standard: Int) {
  fetchGroupedAttributes(standard: $standard) {
    attribute_name
    attribute_type
    attributes {
      id
      title
      standard
      type
      attribute_name
    }
  }
}`;

export const GQ_ADD_CHILD_GLOBAL_ATTRIBUTE = `mutation Mutation($attribute: ChildAttributeInput) {
  createChildAttribute(attribute: $attribute) {
    id
    title
    attribute_name
  }
}`;

export const GQ_ASSIGN_CHILD_ATTIBUTES = `mutation Mutation($attribute: ChildAssignAttribute) {
  createUpdateChildAttribute(attribute: $attribute) {
    id
  }
}`;

export const GQ_GET_CHILD_ASSIGNED_ATTRIBUTES = `query GetAttributesByType($child: String) {
  groupedChildAssignedAttributes(child: $child) {
    attribute_type
    attribute_name
    attributes {
      title
      score
      type
      attribute_name
      id
    }
  }
}`;

export const GQ_UPDATE_ENROLLED_COURSE = `mutation UpdateExistingEnrolledCourse($course: EnrollCourseUpdate) {
  updateExistingEnrolledCourse(course: $course) {
    id
  }
}`;

export const GQ_DELETE_ENROLLED_COURSE = `mutation RemoveEnrolledCourse($removeEnrolledCourseId: String) {
  removeEnrolledCourse(id: $removeEnrolledCourseId) {
    id
  }
}`;

export const GQ_GET_CHILD_REPORT_CARDS = `query GetReportCard($getReportCardId: String) {
  getReportCard(id: $getReportCardId) {
    child
    id
    path
  }
}`;

export const GQ_ADD_CHILD_REPORT_CARD = `mutation AddChildReportCard($addChildReportCardChild2: ChildAddReportCard) {
  addChildReportCard(child: $addChildReportCardChild2) {
    child
    path
  }
}`;

export const GQ_DELETE_CHILD_REPORT_CARD = `mutation RemoveReportCard($removeReportCardId: String) {
  removeReportCard(id: $removeReportCardId) {
    id
  }
}`;

export const GQ_GET_CHILD_CERTIFICATES = `query GetCertificate($getCertificateId: String, $type: String) {
  getCertificate(id: $getCertificateId, type: $type) {
    child
    path
    type
    name
    mimetype
    thumb
    id
  }
}`;

export const GQ_ADD_CHILD_CERTIFICATE = `mutation AddChildCertificates($child: ChildAddCertificate) {
  addChildCertificates(child: $child) {
    child
    path
  }
}`;

export const GQ_DELETE_CHILD_CERTIFICATES = `mutation RemoveCertificate($removeCertificateId: String) {
  removeCertificate(id: $removeCertificateId) {
    id
  }
}`;

export const GQ_GET_CHILD_DEMO_COURSES = `query GetCourseDemoByChild($getCourseDemoByChildId: String) {
  getCourseDemoByChild(id: $getCourseDemoByChildId) {
    user
    headline
    name
    banner
    image
    description
  }
}`;
export const GQ_GET_CHILD_ONGOING_COURSES = `query GetCoursesByChild($getCoursesByChildId: String) {
  getCoursesByChild(id: $getCoursesByChildId) {
    user
    name
    image
    banner
    description
    headline
  }
}`;
export const GQ_GET_CHILD_LIKED_COURSES = `query GetLikedCoursesByChild($getLikedCoursesByChildId: String) {
  getLikedCoursesByChild(id: $getLikedCoursesByChildId) {
    user
    name
    headline
    description
    banner
    image
  }
}`;

export const GQ_GET_CHILD_SCHOOLS = `query GetSchoolByChild($getSchoolByChildId: String) {
  getSchoolByChild(id: $getSchoolByChildId) {
    id
    child
    school_name
    school_board
    state_board_name
    school_end_grade
    school_start_grade
    school_pursuing
  }
}`;
export const GQ_ADD_CHILD_SCHOOL = `mutation AddChildSchool($child: ChildSchoolInput) {
  addChildSchool(child: $child) {
    id
    child
    school_board
    school_name
    state_board_name
    school_end_grade
    school_start_grade
    school_pursuing
  }
}`;
export const GQ_UPDATE_CHILD_SCHOOL = `mutation UpdateChildSchool($child: ChildSchoolUpdate) {
  updateChildSchool(child: $child) {
    id
    school_name
    school_end_grade
    school_start_grade
    school_board
    child
    state_board_name
    school_pursuing
  }
}`;
export const GQ_DELETE_CHILD_SCHOOL = `mutation DeleteChildSchool($deleteChildSchoolId: String) {
  deleteChildSchool(id: $deleteChildSchoolId) {
    id
  }
}`;
export const GQ_UPDATE_CHILD_SLEEP_TIME = `mutation UpdateChildSleepDetails($child: ChildSleepWakeTIme) {
  updateChildSleepDetails(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_FAMILY_INFO = `mutation UpdateChildFamilyInfo($child: ChildFamilyInformation) {
  updateChildFamilyInfo(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_BIRTH_INFO = `mutation UpdateChildBirthInfo($child: ChildBirthInfoUpdate) {
  updateChildBirthInfo(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_SIBLING = `mutation UpdateChildSibling($child: ChildSibling) {
  updateChildSibling(child: $child) {
    id   
  }
}`;

export const GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD = `query GetAttributesByStandard($name: String, $standard: Int) {
  getAttributesByStandard(name: $name, standard: $standard) {
    title
    id
    standard
    type
    attribute_name
    sortOrder
    isApproved
  }
}`;

export const GQ_UPDATE_CHILD_TUITION_INFO = `mutation UpdateTuitionInfo($child: ChildTuitionInfo) {
  updateTuitionInfo(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_SOCIAL_INFO = `mutation UpdateSocialInfo($child: ChildSocialInfo) {
  updateSocialInfo(child: $child) {
    id
  }
}`;

export const GQ_GET_ALL_NOTIFICATIONS = `query GetAllNotifications {
  getAllNotifications {
    title
    message
    link
    createdAt
  }
}`;

export const GET_ALL_FIELDS = `query GetAllfields {
  getAllfields {
    id
    section
    field
    info
    educatingLiners
    appreciatingLiners
    coins
    mandatory
    sectionOrderNumber
    createdAt
    updatedAt
  }
}`;

export const GET_CHILD_EARNED_COINS = `query GetChildFieldsGroup($child: String) {
  getChildFieldsGroup(child: $child) {
    section
    total_coins
    sortOrder
    fields {
      id
      section
      field
      sectioninfo
      info
      educatingLiners
      appreciatingLiners
      coins
      mandatory
      sectionOrderNumber
      createdAt
      updatedAt
      is_earned
    }
  }
}`;

export const GET_CHILD_TOTAL_EARNED_COINS = `query GetTotalEarnedCoins($child: String) {
  getTotalEarnedCoins(child: $child) {
    total
    mandatory
    non_mandatory
    parent
  }
}`;

export const GET_CHILD_FIELDS = `query GetChildFields($child: String) {
  getChildFields(child: $child) {
    section
    total_coins
    sortOrder
    fields {
      id
      section
      field
      sectioninfo
      info
      educatingLiners
      appreciatingLiners
      coins
      mandatory
      sectionOrderNumber
      createdAt
      updatedAt
    }
  }
}`;

export const GQ_GET_DIMENSION_ANSWERED_QUESTION = `query GetDimensionAnsweredQuestion($type: String, $getDimensionAnsweredQuestionId: String, $childId: String) {
  getDimensionAnsweredQuestion(type: $type, id: $getDimensionAnsweredQuestionId, childId: $childId) {
    id
    child
    parent
    dimension_id
    dimension_name
    dimension_description
    dimension_tokens
    exam_questions {
      question_id
      question_text
      question_image_url
      question_options {
        option_text
        option_image_url
        score
      }
      question_answered
    }
    exam_status
    exam_answers
    createdAt
  }
}`;



export const GQ_GET_CHILD_ALL_ADVISORY_CALL = `query getAllAdvisoryCallForChild($childId: String) {
  getAllAdvisoryCallForChild(childId: $childId) {
      id
      First_Name
      Last_Name
      phone
      email
      status
      leadId
      phoneVerifiedAt
      description
      uniqueId
      createdAt
      updatedAt
  }
}`;



export const GQ_GET_OVERVIEW_TEMPLATE = `query GetOverviewTemplates {
  getOverviewTemplates {
    id
    description
  }
}`;

export const GQ_GET_POLYGON_ANALYSIS_REPORT = `query GetPolygonTalentAnalysisReport($childrenId: String) {
  getPolygonTalentAnalysisReport(children_id: $childrenId) {
    id
    children_id
    overview {
      heading
      template_id
      icon
      description {
        child_name
        Personality_trait
        learning_dimensions
        learning_needs
      }
    }
    unique_talent {
      heading
      icon
      description
      talents {
        title
        description
        score
        level
      }
    }
    personality_traits {
      traits {
        description
        title
        image
      }
      description
      icon
      heading
    }
    interest_areas {
      interest {
        description
        title
        image
      }
      description
      icon
      heading
    }
    learning_needs {
      needs {
        title
        learning_dimensions
        talents
        careers
        description
        image
      }
      description
      icon
      heading
    }
    createdAt
  }
}`;

export const GQ_GET_TDP_REPORT = `query GetTDPReport($childrenId: String) {
  getTDPReport(children_id: $childrenId) {
    is_exam_completed
    Report {
      dimension_id
      dimension_name
      max_score
      allotted_score
      cognitive_scoring
      individual_scoring
      allotted_score_message
      allotted_score_label
       celebrity {
        celebrity_role
        celebrity_name
        celebrity_hover_info
        celebrity_info
        celebrity_picture_url
      }
    }
  }
}`;

export const GET_MULTI_DIMENSION_COGNITIVE_REPORT = `query GetMultiDimensionCognitiveReport($childId: String) {
  getMultiDimensionCognitiveReport(childId: $childId) {
    celebrity {
      celebrity_info
      celebrity_hover_info
      celebrity_name
      celebrity_role
      celebrity_picture_url
    }
    dimension_id
    dimension_name
    celebrity_revealed
    cognitive_profile_description
    learning_dimension_description
    related_courses {
      percentage
      course_type
    }
    traits
  }
}`;

export const GQ_GET_DIMENSION_COGNITIVE_REPORT = `query GetDimensionCognitiveReport($dimensionName: String, $childId: String) {
  getDimensionCognitiveReport(dimension_name: $dimensionName, childId: $childId) {

    learning_dimension_description

    cognitive_profile_description
    traits
    related_courses {
      course_type
      percentage
    }
    celebrity {
      celebrity_name
      celebrity_role
      celebrity_hover_info
      celebrity_info
      celebrity_picture_url
    }
    celebrity_revealed
  }
}`;

export const GQ_GET_TRAITS_MAPPING = `query GetTraitsMapping {
  getTraitsMapping {
    id
    dimension_name
    title
  }
}`;

export const GQ_GET_LEARNING_NEEDS = `query LearningNeeds {
  learningNeeds {
    id
    title
  }
}`;

export const GQ_GET_TALENT_NEEDS = `query GetTalentMapping {
  getTalentMapping {
    id
    dimension_name
    title
  }
}`;

export const GQ_GET_CAREERS_MAPPING = `query GetCareers {
  getCareers {
    id
    title
  }
}`;
