export const GQ_GET_USERS = `query Query($searchParams: userParams) {
  users(searchParams: $searchParams) {
    totalItems
    users {
      last_name
      first_name
      email
      phone
      id
      childCount
      business_name
      tokenNumber
      createdAt
    }
    currentPage
  }
}`;
export const GQ_GET_ALL_TEACHER_ASSIGNED_STUDENTS = `query GetAllTeacherAssignedStudents($params: studentParams) {
  getAllTeacherAssignedStudents(params: $params) {
    totalPages
    currentPage
    totalItems
    students {
      id
      name
      grade
      grade_sections {
        grade
        section
      }
      assigned_children_id {
        grade12_child
        school_standard
        report_access_request
        report_access_request_count
        parent {
          first_name
          last_name
        }
        id
      }
      roll_number
      createdAt
      parent_name
      parent_email
      parent_phone
      parent_secondary_email
      parent_secondary_phone
      assigned_teacher_id {
        id
        full_name
        email
      }
      assigned_school_id {
        id
        name_of_school
        city
      }
      report_generated
    full_name
    redirection_url
    }
  }
}
`;

export const GQ_GET_RESET_EMAIL = `mutation SendEmailToParent($student_id: String) {
  sendEmailToParent(student_id: $student_id)}
`;

export const GQ_GET_Delete_Child = `query DeleteStudent($id: String) {
  deleteStudent(id: $id) 
}
`;

export const GQ_GET_ALL_SCHOOL_USERS = `query GetAllSchoolUsers {
  getAllSchoolUsers {
    id
    name_of_school
    city
    address
    official_email_address
    official_phone_number
    state
    pin_code
    principal_name
    trustee_or_owner_name
    createdAt
    show_counselling_session
    show_course_session
  }
}
`;
export const GQ_UPDATE_SCHOOL_SESSION = `mutation UpdateSchoolSession($data: SchoolSessionInput) {
  updateSchoolSession(data: $data) {
    id
  }
}
`;
export const GQ_INVITE_NEW_STUDENT = `mutation InviteNewStudent($data: inviteStudent) {
  inviteNewStudent(data: $data) {
    id
  }
}
`;

export const GQ_SEND_STUDENT_REMINDER = `mutation SendStudentReminder($data: ReminderInput) {
  sendStudentReminder(data: $data)
}
`;

export const GQ_ALLOW_CHILD_REPORT_ACCESS = `mutation AllowReportAccessRequest($child: String, $allow: Boolean) {
  allowReportAccessRequest(child: $child, allow: $allow)
}
`;

export const GQ_GENERATE_PDF_REPORT_ID = `query Query($generatePdfReportId: String) {
  generatePDFReport(id: $generatePdfReportId)
}
`;

export const GQ_GET_ALL_PROVIDERS_WITH_BUSINESS = `query User {
  getAllProvidersWithBusiness {
    logo
    banner
    business_name
    website
    user {
      id
    }
  }
}`;

export const GQ_UPDATE_SECTION_ORDERING = `mutation UpdateSectionOrdering($sections: [sectionOrdering]) {
  updateSectionOrdering(sections: $sections)
}`;

export const GQ_GET_SELECTED_EA_REGIME_PREFERENCE = `query GetSelectedEaRegimePreference {
  getSelectedEaRegimePreference {
    ea_preference_regime
  }
}`;

export const GQ_UPDATE_EA_REGIME_PREFERENCE = `mutation UpdateEaRegimePreference($input: SettingInput) {
  updateEaRegimePreference(input: $input) {
    ea_preference_regime
  }
}`;

export const GQ_GET_FIELDS_GROUP = `query GetfieldsGroup {
  getfieldsGroup {
    section
    sortOrder
  }
}`;

export const GQ_GET_ALL_CHILDREN = `query Query($params: ChildParams) {
  getAllChildren(params: $params) {
    totalItems
    currentPage
    totalPages 
    children {
      id
      first_name
      last_name
        progress
      parent{
        id
        first_name
        last_name
        tokenNumber
        uniqueId
        email
        phone
      }
      city
      date_of_birth
      gender
      school_standard
      grade12_child
      profile_score
      teacher_assigned
      show_course_session
      report_generation_date
      createdAt
      image
      counselor_booked_session_count
      show_course_session
      TDAPercentage
      talent_discovery_bundle
      child_id
      new_user
      amount_paid
      premium_report_seen
      basic_report_seen
    }
  }
}`;

export const GET_CHILD_MANAGE_SLOTS = ` query GetChildManageSlots($keyword: String, $sort: Int, $page: Int) {
  getChildManageSlots(keyword: $keyword, sort: $sort, page: $page) {
    currentPage
    totalItems
    totalPages
    freeSlots {
    last_modified_at
    children_id
    child_image
    child_name
    total_slots
    scheduled_slots
    available_slots
    demo_request_count
    demo_scheduled_count
    counselling_request_count
    counselling_scheduled_count
    }
  }
}
`;
export const GET_TALENT_GUIDANCE = ` query GetTalentGuidanceUser($params: GuidanceParams) {
  getTalentGuidanceUser(params: $params) {
    currentPage
    totalItems
    totalPages
    freeSlots {
    children_id
    child_name
    parent_name
    email
    phone
    comments
    rating
    guidance_status
    lead_id
    slots
    }
  }
}
`;

export const GET_FREE_ADIVISORY_CALL = `query GetAllAdvisoryCallUsers($params: UserParams) {
  getAllAdvisoryCallUsers(params: $params) {
    totalPages
    totalItems
    currentPage
    users {
      id
      First_Name
      Last_Name
      phone
      email
      status
      leadId
      phoneVerifiedAt
      description
      utm_source
      utm_medium
      utm_campaign
      createdAt
      uniqueId
      updatedAt



      start_time
      end_time
      meeting_mode
      status
      cancelled_by
      cancellation_reason
      meeting_url
      contact
    }
  }
}
`;







export const GET_COUNSELING_CALL = `query GetAllCounsellingUsers ($params: UserParams){
  getAllCounsellingUsers(params : $params){
  totalPages
  totalItems
  currentPage
  users {
    id
   child_name
   parent_name
    phone
    email
    status
    description
    unique_id
    start_time
    end_time
    meeting_mode
    status
    meeting_url
    cancellation_reason
    cancelled_by
    meeting_prep_answer
    recording_link
    createdAt
    updatedAt
  }
  }
}
`;
export const GET_COUPUNS = `query GetAllCoupons($params: CouponParams) {
  getAllCoupons(params: $params) {
    totalPages
    totalItems
    currentPage
    coupons {
      coupon_id
      type
      discount
      service
      validity
      used
      remaining
      purpose
      created_by
      max_discount_allowed
      referral_link
      createdAt
      updatedAt
    }
  }
}
`;


export const GET_ALL_REFERRRAL_LINKS = `query getAllReferralLinks{
  getAllReferralLinks
}
`;



export const GET_ALL_PAYMENT_LINK = `query GetAllPaymentLinks($params: UserParams){
  getAllPaymentLinks(params : $params){
     totalPages
     currentPage
     totalItems
     users{
         id
         full_name
         email
         phone
         payment_link
         status
         amount
         generatedBy
         createdAt
         updatedAt
     }
  }
}
`;



export const GET_ALL_SCHOOL_ENQIRY = `query GetAllSchoolEnquiries($params: UserParams) {
  getAllSchoolEnquiries(params: $params) {
    totalPages
    currentPage
    totalItems
    users {
      id
      who_are_you
      full_name
      phone
      email
      name_of_school
      school_city
      school_board
      state_board_name
      school_medium
      createdAt
      updatedAt
    }
  }
}
`;


export const GET_EXPORT_DATA = `query GetExportDataHistory($params: UserParams) {
  getExportDataHistory(params: $params) {
    totalPages
    currentPage
    totalItems
    users {
      id
     batch_name
     batch_status
     startDate
     endDate
     fileUrl
      createdAt
      updatedAt
    }
  }
}
`;





export const GQ_APPLYCOPUN = `query AddCoupon($user : CouponInput) {
  addCoupon(user: $user) {
   talent_discovery_bundle_price
      early_bird_discount
      coupon_discount
      subtotal
      gst
      total
      coupon_type
      discount
  }
}`;






export const GET_FREE_ASSESSMENT_USERS = `query GetAllFreeAssessmentUser($params: UserParams) {
  getAllFreeAssessmentUser(params: $params) {
    totalPages
    totalItems
    currentPage
    users {
      id
      First_Name
      Last_Name
      phone
      email
      leadId
      phoneVerifiedAt
      uniqueId
      is_report_generated
      child_grade
      report_url
      userId
      childId
      utm_source
      utm_medium
      utm_campaign
      createdAt
      updatedAt
    }
  }
}
`;



export const GET_REG_DROPOUT_USERS = `query GetAllRegDropouts($params: UserParams){
  getAllRegDropouts(params: $params){
  totalPages
  totalItems
  currentPage
  users {
    id
    First_Name
    Last_Name
    phone
    email
    status
    leadId
    phoneVerifiedAt
    description
    uniqueId
    utm_source
    utm_medium
    utm_campaign
    createdAt
    updatedAt
  }
}
}
`;



export const GET_CONTACT_US = `query GetContactDetails($params: UserParams) {
  getContactDetails(params: $params) {
      totalPages
      totalItems
      currentPage
    users{
        id
        First_Name
         Last_Name
         city
        phone
        email
        leadId
        message
        createdAt
        updatedAt
        remarks
    }
  }
}
`;

export const GET_PAYMENT_TRACKER = `query GetPaymentTracker($params: PaymentParams) {
  getPaymentTracker(params: $params) {
    totalPages
    totalItems
    currentPage
    users {
      id
      first_name
      last_name
      email
      phone
      userId
      childId
      invoiceTime
      paymentMade
      serviceName
      serviceId
      payment_amount
      createdAt
      updatedAt
      child_name
      subadmin_name
      time
      uniqueId
      testing_payment
      parentId
    }
  }
}
`;

export const GQ_UPDATE_TALENT_DISCOVERY_DATA = `mutation UpdateTalentAdvisoryCallDetails($id: String, $description: String, $status: String, $leadId: String) {
  updateTalentAdvisoryCallDetails(id: $id, description: $description, status: $status, leadId: $leadId) 
}`;

export const GQ_UPDATE_COUNSELLING_DATA = `mutation UpdateCounsellingCallDetails(
  $id: String
  $description: String
  $status: String
  $leadId: String
  $recording_link: String
) {
  updateCounsellingCallDetails(
    id: $id
    description: $description
    status: $status
    leadId: $leadId
    recording_link: $recording_link
  )
}`;


export const GQ_UPDATE_TALENT_GUIDANCE_STATUS = `mutation UpdateTalentGuidanceSession($childrenId: String) {
  updateTalentGuidanceSession(childrenId: $childrenId) {
    message
  }
}`;

export const GQ_CREATE_COPUN = `mutation createCoupon($user: CouponInput) {
  createCoupon(user: $user) 
}`;

export const GQ_TDA_PAYEMENTUSER = `mutation CreateTDAPaymentUser($user: TDAPaymentUserInput) {
  createTDAPaymentUser(user: $user) {
    id
    leadId
    serviceName
    alreadyExist
    talent_discovery_bundle_price
    early_bird_discount
    subtotal
    gst
    total
    request_id
  }
}`;

export const GQ_CHECKEXISTINGUSER = `query CheckExistingUser($email: String, $phone: String){
  checkExistingUser(email: $email, phone: $phone){
       id
       first_name
       last_name
       email
       phone
  }
}`;






export const GQ_UPDATE_BILING_INFO = `mutation UpdateBillingAddressTDA($user: BillingAddressTDAInput) {
  updateBillingAddressTDA(user: $user) {
    id
    leadId
      talent_discovery_bundle_price
      early_bird_discount
      subtotal
      gst
      total
      request_id
  }
}`;


export const GQ_UPDATE_BILING_INFO2 = `mutation UpdateBillingInfo($user: BillingAddressInput) {
  updateBillingInfo(user: $user) {
    id
    leadId
      talent_discovery_bundle_price
      early_bird_discount
      subtotal
      gst
      total
      request_id
  }
}`;

export const GQ_PAYMENT_STATUS = `query CheckPaymentStatus($childId : String, $serviceName: String){
  checkPaymentStatus(childId: $childId, serviceName: $serviceName)
}`;




export const GQ_CREATEADMINORDER = `mutation AdminOrder($user: OrderInput) {
  adminOrder(user: $user) {
   orderid
   requestId
   finalamount
   name
   contact 
   email
   longurl
  }
}`;



export const GQ_EDIT_COPUN = `mutation editCoupon($user : CouponInput){
  editCoupon(user: $user)
}`;


export const GQ_DELETE_COPUN = `mutation DeleteCoupon($coupon_id : String){
  deleteCoupon(coupon_id : $coupon_id)
}`;




export const GQ_UPDATE_CONTACT_US = `mutation UpdateContactUs($id: String, $remarks: String) {
  updateContactUs(id: $id, remarks: $remarks) 
}`;

export const GET_CHILDREN_FREE_SLOTS = `query GetChildrenFreeSlots($childrenId: String) {
  getChildrenFreeSlots(children_id: $childrenId) {
    children_id
    child_name
    child_images
    freeSlots {
      date
      start_time
      end_time
    }
    
    counsellorBookings {
      status
      createdAt
      updatedAt
      counsellor_course_name
      counsellor_course_id
      provider_name
      request_type
    }
    available_slots
    total_slots
  }
}`;

export const GET_CHILDREN_TALENT_GUIDANCE_SLOTS = `query GetTalentGuidanceSlots($children_id: String) {
  getTalentGuidanceSlots(children_id: $children_id) {
        children_id
    child_images
    child_name
    freeSlots {
   date
   start_time
   end_time
    }
    available_slots
    total_slots
  }
}
`;

export const GET_CHILDREN_BOOKING_REQUEST = `query GetChildrenBookingRequest($childrenId: String, $status: String) {
  getChildrenBookingRequest(children_id: $childrenId, status: $status) {
  scheduled_id {
      id
      trainer_name
      session_date
      session_time
      session_url
      session_comment
    }
    demo_joined
    counsellor_course_name
    counsellor_course_id
    provider_name
    request_type
    status
    createdAt
    updatedAt
    id
  }
}`;

export const GQ_GET_SINGLE_USER_DETAIL = `query User($userId: String) {
  user(id: $userId) {
    id
    first_name
    childCount
    business_name
    role
    phone
    email
    last_name
    createdAt
    no_of_successful_referral
    password
    device_type
    operating_system
    browser_name
    browser_version
    OS_version
    login_time
    logout_time
  }
}`;

export const GQ_CREATE_COURSE_WITH_ALL_INFO = `mutation CreateCourseWithAllInfo($course: CourseInput) {
  createCourseWithAllInfo(course: $course)
}`;

export const GQ_EDIT_COURSE_WITH_ALL_INFO = `mutation EditCourseWithAllInfo($courseId: String, $course: CourseInput) {
  editCourseWithAllInfo(course_id: $courseId, course: $course)
}`;

// creating attributes
export const GQ_CREATE_COURSE_ATTRIBUTE = `mutation CreateCourseAttribute($title: String, $type: String) {
  createCourseAttribute(title: $title, type: $type) {
    title
    id
  }
}`;
export const GQ_GET_COURSE_ATTRIBUTES = `query GetCourseAttributes($type: String) {
  getCourseAttributes(type: $type) {
    id
    title
  }
}`;

export const GQ_GET_ALL_COURSES = `query SearchCourses($params: CourseFilter) {
  searchCourses(params: $params) {
    totalPages
    totalItems
    courses {
      id
      provider_name
      banner {
        path
        mimetype
      }
      price
      course_levels
      name
      course_hours
      course_urls
      website
      website_link
      platform_reference
      google_store_link
      apple_store_link
      status
    }
  }
}`;

export const GQ_ENABLE_DISABLE_COURSE = `mutation UpdateCourseStatus($course: String, $status: String) {
  updateCourseStatus(course: $course, status: $status) {
    id
  }
}`;

export const GQ_GET_CHILD_BY_PARENT = `query GetChildren($getChildrenId: String) {
  getChildren(id: $getChildrenId) {
    id
    first_name
    last_name
    school_standard
    grade12_child
    date_of_birth
    place_of_birth
    time_of_birth
    about
    image
    banner
    gender
    city
    sleep_time{
        start
        end
    }
    wake_time{
        start
        end
    }
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
    subjects {
      subjectId
      subjectName
      score
    }
    habits {
      habitName
      habitId
    }
    skills {
      score
      skillName
      skillId
    }
    interests {
      interestId
      interestName
    }
    hobbies {
      hobbyName
      hobbyId
    }
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
    favourite_food {
      foodName
      foodId
    }
    favourite_movie {
      movieName
      movieId
    }
    favourite_music {
      musicName
      musicId
    }
    favourite_sport {
      sportId
      sportName
    }
   family_information {
      family_type
      live_with_grandparents
      father_profession
      father_company_name
      father_income
      father_profession_info
      mother_profession_info
      mother_profession
      mother_company_name
      mother_income
      child_sibling_count
      child_sibling_combination
    }
    expectations
    concerns
    fears
    coursesChoice
    parent_note
    child_tuition_other_subjects
    child_take_tuition
    school_name
    school_board
    state_board_name
    last_year_percent
    child_live_away
    start_month_for_hostel
    start_year_for_hostel
    end_month_for_hostel
    end_year_for_hostel
    currently_living
    psychologically_assessed
    learning_disability
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
      youtube
      other
    }
    parent {
      id
      first_name
      last_name
      tokenNumber
    }
  }
}`;

export const GQ_GET_CHILD_BY_PARENT_NEW = `query GetChildrenNew($getChildrenId: String) {
  getChildrenNew(id: $getChildrenId) {
    id
    first_name
    last_name
    school_standard
    grade12_child
    date_of_birth
    place_of_birth
    time_of_birth
    about
    image
    banner
    gender
    city
    sleep_time{
        start
        end
    }
    wake_time{
        start
        end
    }
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
    subjects {
      subjectId
      subjectName
      score
    }
    habits {
      habitName
      habitId
    }
    skills {
      score
      skillName
      skillId
    }
    interests {
      interestId
      interestName
    }
    hobbies {
      hobbyName
      hobbyId
    }
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
    favourite_food {
      foodName
      foodId
    }
    favourite_movie {
      movieName
      movieId
    }
    favourite_music {
      musicName
      musicId
    }
    favourite_sport {
      sportId
      sportName
    }
   family_information {
      family_type
      live_with_grandparents
      father_profession
      father_company_name
      father_income
      father_profession_info
      mother_profession_info
      mother_profession
      mother_company_name
      mother_income
      child_sibling_count
      child_sibling_combination
    }
    expectations
    concerns
    fears
    coursesChoice
    parent_note
    child_tuition_other_subjects
    child_take_tuition
    school_name
    school_board
    state_board_name
    last_year_percent
    child_live_away
    start_month_for_hostel
    start_year_for_hostel
    end_month_for_hostel
    end_year_for_hostel
    currently_living
    psychologically_assessed
    learning_disability
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
      youtube
      other
    }
    parent {
      id
      first_name
      last_name
      tokenNumber
    }
    report_generation_date
    status
  }
}`;

export const GQ_GET_CHILD_UPDATE = `query UpdateChildrenNew($id: String) {
  updateChildrenNew(id: $id) 
}`;

export const GQ_GET_PROVIDER_ENQUIRIES = `query Query($params: providerParams) {
    providerInquiries(params: $params) {
        totalItems
        providers {
            id
            company_name
            email
            website
            logo
            first_name
            last_name
            phone {
              number
              code
              name
           }
            status
            createdAt
            phoneVerifiedAt
            emailVerifiedAt
        }
        currentPage
        totalItems
    }
}`;

export const GQ_UPDATE_PROVIDER_STATUS = `mutation Mutation($status: ProviderStatusInput) {
  changeProviderStatus(status: $status) {
    id
  }
}`;

export const GQ_GET_PARENT_EARLY_ACCESS = `query EarlyAccessUsers($searchParams: earlyAccessParams) {
  earlyAccessUsers(searchParams: $searchParams) {
    totalPages
    currentPage
    totalItems
    users {
      id
      first_name
      last_name
      referral_token
      no_of_successful_referral
      no_of_page_views
      email
      parent_type
      phone
      business_name
      role
      tokenNumber
      createdAt
      childCount
      phoneVerifiedAt
      emailVerifiedAt
      status
      parent_type
      having_children
      registration_source
      nonApprovedLoginTrigger
      total_payments
      registered_time
      last_login_time
      paid_user
      parent_id
      type
      utm_source
      utm_medium
      utm_campaign
      dashboard_link
    }
  }
}`;

export const GQ_GENERATE_SINGLE_LOGIN = `mutation GenerateParentLogin($id: String) {
  GenerateParentLogin(id: $id) {
    tokenNumber
  }
}`;

export const GQ_GENERATE_BULK_LOGIN = `mutation GenerateParentLoginBulk($searchParams: bulkLoginParams) {
  generateParentLoginBulk(searchParams: $searchParams) {
    id
    
  }
}`;

export const GQ_SEND_EMAIL_PHONE_VERIFICATION = `mutation SendEmailPhoneVerification($sendEmailPhoneVerificationId: ID, $type: String) {
  sendEmailPhoneVerification(id: $sendEmailPhoneVerificationId, type: $type) {
    id
  }
}`;

export const GQ_EXPORT_EARLY_ACCESS_USERS = `query ExportEarlyAccessUsers($from: Int, $to: Int) {
  exportEarlyAccessUsers(from: $from, to: $to) {
    location
    mimetype
  }
}`;

export const GQ_EXPORT_NO_CHILD_USERS = `query ExportNoChildUsers(
  $from: Int
  $to: Int
  $startDate: String
  $endDate: String
) {
  exportNoChildUsers(
    from: $from
    to: $to
    startDate: $startDate
    endDate: $endDate
  ) {
    location
    mimetype
  }
}`;

export const GQ_EXPORT_COMPLETE_CHILD_USERS = `query ExportCompleteTestUsers(
  $from: Int
  $to: Int
  $startDate: String
  $endDate: String
) {
  exportCompleteTestUsers(
    from: $from
    to: $to
    startDate: $startDate
    endDate: $endDate
  ) {
    location
    mimetype
  }
}`;

export const GQ_EXPORT_ASSESSMENT_COM_REPORT = `query ExportCompleteTestRangeUsers(
  $startDate: String
  $endDate: String
) {
  exportCompleteTestRangeUsers(
    startDate: $startDate
    endDate: $endDate
  ) {
    location
    mimetype
  }
}`;


export const GQ_EXPORT_INCOMPLETE_CHILD_USERS = `query ExportIncompleteTestUsers(
  $from: Int
  $to: Int
  $startDate: String
  $endDate: String
) {
  exportIncompleteTestUsers(
    from: $from
    to: $to
    startDate: $startDate
    endDate: $endDate
  ) {
    location
    mimetype
  }
}`;


export const GQ_EXPORT_PAYMENT_TRACKER_USERS = `query ExportTDAPaymentUsers($startDate: String, $endDate: String) {
  exportTDAPaymentUsers(startDate: $startDate, endDate: $endDate) {
    location
    mimetype
  }
}`;



export const GQ_MARK_USER_AS_ARCHIVED = `mutation MarkUserAsArchived($markUserAsArchivedId: String) {
  markUserAsArchived(id: $markUserAsArchivedId)
}`;

export const GQ_DELETE_ARCHIVED_USER = `mutation DeleteArchivedUser($deleteArchivedUserId: String) {
  deleteArchivedUser(id: $deleteArchivedUserId)
}`;

export const GQ_ARCHIVED_USERS = `query ArchivedUsers($page: Int) {
  archivedUsers(page: $page) {
    currentPage
    totalItems
    totalPages
    users {
        id
       first_name
       last_name
       email
       phone
       business_name
       role
       having_children
       tokenNumber
       archivedAt
       createdAt
    }
  }
}
`;

export const GQ_DELETED_USERS = `query DeletedUsers($page: Int) {
  deletedUsers(page: $page) {
    currentPage
    totalItems
    totalPages
    users {
      email
    childCount
    business_name
    createdAt
    deletedAt
    deletedBy {
      full_name
      id
    }
    first_name
    emailVerifiedAt
    having_children
    id
    last_name
    parent_type
    phone
    phoneVerifiedAt
    referral_token
    registration_source
    role
    status
    token
    updatedAt
    tokenNumber
    }
  }
}`;

export const GQ_ADMIN_ACTIVITY_LOGS = `query GetAdminActivityLogs($page: Int) {
  getAdminActivityLogs(page: $page) {
    currentPage
    totalItems
    totalPages
    logs {
      action_message
      action_preference
      page
      createdAt
      updatedAt
      admin {
        id
        full_name
        email
      }
    }
  }
}`;

export const GQ_FETCH_GROUP_ATTRIBUTES = `query FetchGroupedAttributes {
  fetchGroupedAttributes {
    attribute_name
    attribute_type
    sortOrder
  }
}`;

export const GET_FIELD_GROUP = `query GetfieldsGroup {
    getfieldsGroup {
        section
        sectionTitle
        total_coins
        sortOrder
        fields {
          sectioninfo
            field
            info
            section
            educatingLiners
            appreciatingLiners
            coins
            mandatory
            sectionOrderNumber
        }
    }
}`;

export const GQ_CREATE_CHILD_ATTRIBUTE = `mutation CreateChildAttribute($attribute: ChildAttributeInput) {
  createChildAttribute(attribute: $attribute) {
    id
  }
}`;

export const GQ_UPDATE_SECTION_INFORMATION = `mutation UpdateSectionInformation($input: sectionInfoUpdate) {
  updateSectionInformation(input: $input)
}`;

export const GQ_UPDATE_SECTION_FIELD_INFORMATION = `mutation UpdateSectionFieldInformation($input: sectionFieldInfoUpdate) {
  updateSectionFieldInformation(input: $input)
}`;

export const GQ_UPDATE_CHILD_SINGLE_ATTRIBUTE = `mutation UpdateChildAttribute($attributeId: String, $attribute: ChildAttributeInput) {
  updateChildAttribute(attribute_id: $attributeId, attribute: $attribute) {
    id
  }
}`;

export const GQ_UPDATE_SCHOOL_SINGLE_ATTRIBUTE = `mutation UpdateSchool($updateSchoolId: ID, $name: String) {
  updateSchool(id: $updateSchoolId, name: $name) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_ATTRIBUTE = `mutation UpdateAttributeSortOrder($attributeName: String, $sortOrder: Float) {
  updateAttributeSortOrder(attribute_name: $attributeName, sortOrder: $sortOrder) {
    id
  }
}`;

export const GQ_SINGLE_ATTRIBUTE_DETAILS = `query GetAttributesByType($filter: childFilter) {
  getAttributesByType(filter: $filter) {
    id
    title
    standard
    type
    attribute_name
    sortOrder
    isApproved
    createdAt
    icon
  }
}`;

export const GQ_DELETE_PROVIDER_CATEGORY = `mutation DeleteProviderCategory($deleteProviderCategoryId: ID) {
  deleteProviderCategory(id: $deleteProviderCategoryId) {
    id
  }
}`;

export const GQ_DELETE_CHILD_ATTRIBUTE = `mutation DeleteChildAttribute($name: String) {
  deleteChildAttribute(name: $name) {
    id
  }
}`;

export const GQ_DELETE_CHILD_ATTRIBUTE_BY_ID = `mutation DeleteChildAttributeById($deleteChildAttributeByIdId: ID) {
  deleteChildAttributeById(id: $deleteChildAttributeByIdId) {
    id
  }
}`;

export const GQ_DELETE_SCHOOL_ATTRIBUTE_BY_ID = `mutation DeleteSchool($deleteSchoolId: ID) {
  deleteSchool(id: $deleteSchoolId)
}`;

export const GQ_FETCH_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTES = `query ParentExpectationsFearsConcernsByType($type: String, $query: String) {
  parentExpectationsFearsConcernsByType(type: $type, query: $query) {
    id
    name
    icon
    whiteicon
    isApproved
    createdAt
    updatedAt
  }
}`;

export const GQ_UPDATE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID = `mutation UpdateParentExpectationFearConcerns($updateParentExpectationFearConcernsId: ID, $name: String, $whiteicon: String, $type: String) {
  updateParentExpectationFearConcerns(id: $updateParentExpectationFearConcernsId, name: $name, whiteicon: $whiteicon, type: $type)
}`;

export const GQ_APPROVE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID = `mutation ApprovedParentExpectationFearConcerns($approvedParentExpectationFearConcernsId: ID, $type: String, $status: Int) {
  approvedParentExpectationFearConcerns(id: $approvedParentExpectationFearConcernsId, type: $type, status: $status)
}`;

export const GQ_DELETE_PARENT_EXPECTATION_FEAR_CONCERN_ATTRIBUTE_BY_ID = `mutation DeleteParentExpectationFearConcerns($deleteParentExpectationFearConcernsId: ID, $type: String) {
  deleteParentExpectationFearConcerns(id: $deleteParentExpectationFearConcernsId, type: $type)
}`;

export const GQ_FETCH_ONLINE_COURSE_ATTRIBUTES = `query GetOnlineCourse($query: String) {
  getOnlineCourse(query: $query) {
    id
    name
    icon
    blueicon
    isApproved
    createdAt
    updatedAt
  }
}`;

export const GQ_APPROVE_ONLINE_COURSE_ATTRIBUTES = `mutation ApprovedOnlineCourse($approvedOnlineCourseId: ID, $status: Int) {
  approvedOnlineCourse(id: $approvedOnlineCourseId, status: $status) {
    id
  }
}`;

export const GQ_UPDATE_ONLINE_COURSE_ATTRIBUTES = `mutation UpdateOnlineCourse($updateOnlineCourseId: ID, $name: String, $icon: String, $blueicon: String) {
  updateOnlineCourse(id: $updateOnlineCourseId, name: $name, icon: $icon, blueicon: $blueicon) {
    id
  }
}`;

export const GQ_DELETE_ONLINE_COURSE_ATTRIBUTES = `mutation DeleteOnlineCourse($deleteOnlineCourseId: ID) {
  deleteOnlineCourse(id: $deleteOnlineCourseId)
}`;

export const GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID = `mutation ApproveChildAttributeById($approveChildAttributeByIdId: ID, $status: Int) {
  approveChildAttributeById(id: $approveChildAttributeByIdId, status: $status) {
    id
  }
}`;

export const GQ_APPROVE_SCHOOL_ATTRIBUTE_BY_ID = `mutation ApproveSchoolAttributeById($approveSchoolAttributeByIdId: ID, $status: Int) {
  approveSchoolAttributeById(id: $approveSchoolAttributeByIdId, status: $status) {
    id
  }
}`;

export const GQ_APPROVE_PROVIDER_CATEGORY = `mutation ApproveProviderCategory($approveProviderCategoryId: ID) {
  approveProviderCategory(id: $approveProviderCategoryId) {
    id
  }
}`;

export const GQ_DELETE_ONLINE_COURSE = `mutation DeleteOnlineCourse($deleteOnlineCourseId: ID) {
  deleteOnlineCourse(id: $deleteOnlineCourseId) {
    id
  }
}`;

export const GQ_DELETE_PROGRAM_TYPE = `mutation DeleteProgramListing($deleteProgramListingId: ID) {
  deleteProgramListing(id: $deleteProgramListingId) {
    name
  }
}`;

export const GQ_APPROVE_PROGRAM_TYPE = `mutation ApproveProgramListing($approveProgramListingId: ID) {
  approveProgramListing(id: $aproveProgramListingId) {
    name
  }
}`;

export const GQ_GET_PROVIDER_CATEGORIES_ADMIN = `query GetAllProviderCategoryAdmin {
  getAllProviderCategoryAdmin {
    category
    id
    isApproved
    createdAt
  }
}`;

export const GQ_GET_FAQ_CATEGORY = `query GetSiteFaqCategories {
  getSiteFaqCategories {
    id
    name
    icon
    createdAt
  }
}`;

export const GQ_GET_ALL_FEEDBACK = `query GetAllFeedback($data: FeedbackFilterInput) {
  getAllFeedback(data: $data) {
    currentPage
    totalItems
    totalPages
    feedback {
      id
      rating
      parent {
        phone
        email
        first_name
        last_name
      }
      title
      description
      createdAt
    }
  }
}`;

export const GQ_GET_LD_PERCENTAGE_CONTRIBUTION = `query GetLdPercentageContribution {
  getLdPercentageContribution {
    type
    id
    title
    weightage
  }
}`;

export const GQ_UPDATE_LD_CONTRIBUTION = `mutation UpdateLdContribution($input: [LdPercentageCountributionInput]) {
  updateLdContribution(input: $input) {
    type
    title
    weightage
  }
}`;

export const GQ_GET_ALL_REPORTED_REVIEW = `query GetAllReportedReview($data: ReportedFilterInput) {
  getAllReportedReview(data: $data) {
    id
    commented_parent {
    
      first_name
      last_name
       email
      phone
    }
    reported_parent {
      id
      last_name
      first_name
      email
      phone
    }
    course {
      name
    }
    comment
    createdAt
  }
}`;

export const GQ_ADD_FAQ_CATEGORY = `mutation AddSiteFaqCategory($name: String, $icon: String) {
  addSiteFaqCategory(name: $name, icon: $icon) {
    id
  }
}`;

export const GQ_EDIT_FAQ_CATEGORY = `mutation EditSiteFaqCategory($editSiteFaqCategoryId: ID, $name: String, $icon: String) {
  editSiteFaqCategory(id: $editSiteFaqCategoryId, name: $name, icon: $icon) {
    id
  }
}`;

export const GQ_DELETE_FAQ_CATEGORY = `mutation DeleteSiteFaqCategory($deleteSiteFaqCategoryId: ID) {
  deleteSiteFaqCategory(id: $deleteSiteFaqCategoryId) {
    id
  }
}`;

export const GQ_ADD_SITE_FAQ = `mutation AddSiteFaq($faq: SiteFaqInput) {
  addSiteFaq(faq: $faq) {
   id 
  }
}`;

export const GQ_EDIT_SITE_FAQ = `mutation EditSiteFaq($editSiteFaqId: ID, $faq: SiteFaqInput) {
  editSiteFaq(id: $editSiteFaqId, faq: $faq) {
    id
  }
}`;

export const GQ_DELETE_SITE_FAQ = `mutation DeleteSiteFaq($deleteSiteFaqId: ID) {
  deleteSiteFaq(id: $deleteSiteFaqId) {
    id
  }
}`;

export const GQ_GET_ALL_SITE_FAQ = `query GetAllSiteFaqs($keywords: String, $categories: [String], $page: Int) {
  getAllSiteFaqs(keywords: $keywords, categories: $categories, page: $page) {
    faqs {
      id
      title
      descriptions
      categories
      sort_order
    }
    total
  }
}`;

export const GQ_ADMIN_SEND_NOTIFICATION = `mutation Mutation($inputData: NotificationInput) {
  sendNotification(inputData: $inputData)
}`;

export const GQ_ADD_SITE_FAQ_BULK = `mutation Mutation($file: Upload) {
  addSiteFaqBulk(file: $file) {
    id
  }
}`;

export const GQ_SCHEDULE_CHILDREN_REQUEST = `mutation ScheduleChildrenRequest($data: ScheduleRequestInput) {

  scheduleChildrenRequest(data: $data) {

    id

  }

}`;
export const GQ_SCHEDULE_DEMO_CHILDREN_REQUEST = `mutation ScheduleDemoChildrenRequest($data: ScheduleDemoInput) {
  scheduleDemoChildrenRequest(data: $data) {
    id
  }
}`;

export const GQ_SESSION_PROVIDER_EMAIL = `mutation SessionProviderEmail($scheduleId: String) {
  SessionProviderEmail(schedule_id: $scheduleId)
}`;

export const GQ_EDIT_SCHEDULE_CHILDREN_REQUEST = `mutation EditScheduleChildrenRequest($data: EditScheduleRequestInput) {
  editScheduleChildrenRequest(data: $data) {
    id 
  }
}`;

export const GQ_GET_DEMO_REVIEW_BY_REQUEST_ID = `query GetDemoReviewsByRequestId($requestId: String) {
  getDemoReviewsByRequestId(request_id: $requestId) {
    id
    request_id
    overall_ratings
    provider_ratings
    requirement_action
    comment
    createdAt
    updatedAt
    user_id {
      first_name
      last_name
    }
  }
}`;

export const GQ_SEND_MARKETTING_EMAIL = `mutation Mutation($inputData: EmailInput) {
  sendMarketingEmail(inputData: $inputData)
}`;

export const GQ_EDIT_TEMPLATE_EMAIL = `mutation Mutation($editEmailTemplateId: ID, $inputData: EmailTemplateInput) {
  editEmailTemplate(id: $editEmailTemplateId, inputData: $inputData) {
    subject
    id
    title
    content
    attachments
  }
}`;
export const GQ_EDIT_EA_LANDING_PAGE = `mutation EditEALandingPage($input: EALandingPageInput) {
  editEALandingPage(input: $input) {
    id
  }
}`;

export const GQ_ADD_TEMPLATE_EMAIL = `mutation AddEmailTemplate($inputData: EmailTemplateInput) {
  addEmailTemplate(inputData: $inputData) {
    id
    title
    subject
    content
    attachments
  }
}`;
export const GQ_CREATE_EA_LANDING_PAGE = `mutation CreateEALandingPage($input: EALandingPageInput) {
  createEALandingPage(input: $input) {
    id
  }
}`;

export const GQ_DELETE_TEMPLATE_EMAIL = `mutation DeleteEmailTemplate($deleteEmailTemplateId: ID) {
  deleteEmailTemplate(id: $deleteEmailTemplateId) {
    id
  }
}`;

export const GQ_GET_ALL_EMAIL_TEMPLATES = `query GetAllEmailTemplates {
  getAllEmailTemplates {
   id
    title
    type
    condition
    description
    is_default
    subject
    template_preference
    custom_template
    content
    attachments
    createdAt
  }
}`;
export const GQ_GET_ALL_EMAIL_TEMPLATES_TYPES = `query GetAllEmailTemplateTypes {
  getAllEmailTemplateTypes {
    id
    template_type
    template_type_parameters
    template_type_slug
    template_condition
  }
}`;
export const GQ_GET_ALL_EA_LANDING_LIST = `query getListingEAPages {  
getListingEAPages {  
  id  
  landing_page_title
  landing_page_description 
  landing_page_bullet_points
  landing_page_image 
  landing_page_type 
  landing_page_bullet_point_images
  landing_page_bullet_point_sort_index 
  landing_page_unique
   }}`;

export const GQ_DELETE_EA_LANDING_PAGE_DETAILS = `mutation DeleteEALandingPage($uniqueId: String) {
  deleteEALandingPage(unique_id: $uniqueId) {
     message
  }
}`;

export const GQ_GET_ALL_MARKETING_EMAILS = `query GetAllMarketingEmails {
  getAllMarketingEmails {
    id
    content
    subject
    users {
      id
      first_name
      last_name
      tokenNumber
    }
    attachments
  }
}`;

export const GQ_ADMIN_SEND_EMAIL = `mutation SendMarketingEmail($inputData: EmailInput) {
  sendMarketingEmail(inputData: $inputData)
}`;

export const GQ_GET_USER_GROUP = `query GetUserGroup($params: userGroupParams) {
  getUserGroup(params: $params) {
    totalPages
    totalItems
    usergroups {
      id
      group_name
      users {
        id
      }
      createdAt
    }
  }
}`;

export const GQ_DELETE_USER_FROM_GROUP = `mutation Mutation($inputData: deleteGroupInput) {
  deleteuserFromGroup(inputData: $inputData) {
    id
  }
}`;

export const GQ_ADMIN_ADD_GROUP = `mutation AddUserGroup($inputData: userGroupInput) {
  addUserGroup(inputData: $inputData) {
    id
    group_name
  }
}`;

export const GQ_GET_GROUP_BY_ID = `query GetGroup($inputData: GetGroup) {
  getGroup(inputData: $inputData) {
    id
    group_name
    type
    users {
      id
      parent
      email
      first_name
      last_name
        token
      phone
    }
    createdAt
  }
}`;

export const GQ_UPDATE_GROUP_DATA = `mutation AddNewUserToGroup($inputData: addNewUserToGroupInput) {
  addNewUserToGroup(inputData: $inputData) {
    id
  }
}`;

export const GQ_DELETE_GROUP = `mutation Mutation($deleteGroupId: String) {
  deleteGroup(id: $deleteGroupId) {
    id
  }
}`;

export const GQ_UPDATE_BULLET_POINT_ICONS = `mutation AddBulletPointIcons($name: String) {
  addBulletPointIcons(iconsName: $name) {
    message
  }
}`;

export const GQ_GET_EMAIL_LOGS = `query GetAllMarketingEmails {
  getAllMarketingEmails {
    id
    createdAt
    attachments
    sent
    users {
      id
    }
  }
}`;

export const GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS = `query Query($groupNotificationsEmailsId: ID) {
  groupNotificationsEmails(id: $groupNotificationsEmailsId) {
    notifications {
      title
      message
      link
      users {
        last_name
        tokenNumber
        first_name
      }
      createdAt
    }
    emails {
      subject
      content
      users {
        first_name
        last_name
        tokenNumber
      }
      sent
      createdAt
    }
  }
}`;

export const GQ_GET_ALL_EA_LANDING_ICON_LIST = `query GetListingEAPageIcons {  
  getListingEAPageIcons {
    icon_image_path
     }}`;
export const GQ_GET_COURSE_REVIEWS = `query CourseReviews($course: GetReviewInput) {
  courseReviews(course: $course) {
    reviews {
      id 
      createdAt
      children {
        first_name
        last_name
        image
      }
    
      overall_course_rating
      headline
      description
      is_review_helpful{
      parent
        report
      }
      is_reported_abusive 
      parent {
        first_name
        last_name
        id
      }
    }
    recommended
    average_rating
    progress_ratings {
      rating
      percentage
    }
  }
}`;




export const GQ_TOTAL_USERS = `query GetTotalUsers($params : UserParam){
  getTotalUsers(params: $params)
}`;


export const GQ_TOTAL_PAID_USERS = `query GetTotalPaidUsers($params : UserParam){
  getTotalPaidUsers(params: $params)
}`;


export const GQ_TOTAL_PAYMENT_COLLECTED = `query GetTotalCollectedPayment( $params: UserParam){
  getTotalCollectedPayment(params: $params)
}`;


export const GQ_TOTAL_REPORT_GENERATED = `query GetTotalReportsGenerated($params: UserParam){
  getTotalReportsGenerated(params: $params)
}`;



export const GQ_RECENT_PAYMENTS = `query GetRecentPayments($params: UserParam) {
  getRecentPayments(params: $params) {
    totalPages
    totalItems
    currentPage
    users {
      first_name
      last_name
      payment_amount
      serviceName
      serviceId
      childId
      email
      phone
      paymentMade
      invoiceTime
      userId
    }
  }
}`;



export const GQ_ACCESS_NOW_ADMIN = `query AccessNowAdmin($id: String){
  accessNowAdmin(id: $id){
      id
      accessNowToken
  }
}`;


export const GQ_DOWNLOAD_INVOICE = `query DownloadInvoice($request_id : String, $serviceId : String) {
  downloadInvoice(request_id: $request_id, serviceId: $serviceId) 
}`;

export const GQ_DELETED_USERS_SURVEY = `query GetDeletedUserSurvey($email: String) {
  getDeletedUserSurvey(email: $email){
     survey_questions_answers{
      question_text
      answer_text
     }
     additional_feeback
  }
}`;


export const GQ_UPDATE_PUNCHING_DETAILS = `mutation UpdatePunchingDetails($id: String, $subadmin_name: String) {
  updatePunchingDetails(id: $id, subadmin_name: $subadmin_name)
}`;


export const GQ_GET_TOTAL_TAC_USERS = `query GetTotalTACusers($params: UserParam){
  getTotalTACusers(params: $params)
}`;



export const GQ_GET_TOTAL_PAYMENT_LINK_GENERATED = `query GetTotalPaymentLinkGenerated($params: UserParam){
  getTotalPaymentLinkGenerated(params: $params)
}`;


export const GQ_MARK_AS_TESTING_PAYMENT = `mutation MarkAsTestingPayment($id: String){
  markAsTestingPayment(id : $id)
}`;


export const GQ_GET_TOTAL_CHILDREN = `query GetTotalChildren($params: UserParam){
  getTotalChildren(params: $params)
}`;


export const GQ_GET_PREMIUM_REPORT_GENERTAED = `query GetPremiumReportGenerated($params: UserParam){
  getPremiumReportGenerated(params: $params)
}`;


export const GQ_UPDATE_CHILD_REPORT_STATUS = `mutation UpdateChildReportStatus($id: String){
  updateChildReportStatus(id: $id)
}
`;



export const GQ_EXPORT_DOWNLOAD_REPORT_DATA = `query ExportDownloadReportData($from: Int $to: Int $startDate: String $endDate: String$report_type: String)
 {
  exportDownloadReportData(
    from: $from
    to: $to
    startDate: $startDate
    endDate: $endDate
    report_type: $report_type
  ) {
    location
    mimetype
  }
}
`;


export const GQ_EXPORT_GET_ALL_AFFILLIATES= `query getAllAffiliates($params: UserParams){
  getAllAffiliates(params: $params){
      totalPages
      currentPage
      totalItems
      users{
          id
          full_name
          email
          phone
          user_name
          commission
          commission_percentage
          status
          createdAt
          updatedAt
      }
  }
}

`;




export const GQ_CHILD_ASSESSMENT_INFO_DATA = `mutation RequestChildAssessmentInfo($batchInput: BatchInput) {
  RequestChildAssessmentInfo(batchInput: $batchInput) {
    batch_name
    batch_status
  }
}
`;


export const GQ_SAVE_ALREADY_PAID_PAYMENT_DETAILS = `mutation SaveAlreadyPaidPaymentDetails($user: AlreadyPaidPaymentInput) {
  saveAlreadyPaidPaymentDetails(user: $user)
}
`;


export const GQ_ADMIN_ORDER_TDA = `mutation AdminOrderTDA($user: OrderInput) {
  adminOrderTDA(user: $user) {
   requestId
   finalamount
   name
   contact 
   email
   longurl
  }
}
`;

export const GQ_ADMIN_CREATE_AFFILIATES = `mutation createAffiliates($input : AffiliatesInput){
  createAffiliates(input: $input){
     id
     user_name
  }
}
`;

export const GQ_ADMIN_DELETE_AFFILIATES = `mutation deleteAffiliate($id: String){
  deleteAffiliate(id: $id)
}
`;


export const GQ_ADMIN_INACTIVE_AFFILIATES = `mutation inactiveAffiliate($id: String){
  inactiveAffiliate(id: $id)
}
`;


export const GQ_ADMIN_EDIT_AFFILIATES = `mutation editAffiliate(
  $id: String
  $promocode: String
  $commission_percentage: Float
) {
  editAffiliate(
    id: $id
    promocode: $promocode
    commission_percentage: $commission_percentage
  )
}
`;







































