
export const GQ_GET_ALL_CELEBRITY = `query Celebrities($searchParams: celebrityParams) {
  celebrities(searchParams: $searchParams) {
    totalPages
    allcelebrities {
      id
      dimension_name
    celebrity_name
    celebrity_role
    celebrity_hover_info
    celebrity_info
    celebrity_picture_url
    createdAt
    updatedAt
    }
    currentPage
    totalItems
  }
}`;
export const GQ_CREATE_CELEBRITY = `mutation CreateCelebrity($celebrity: CelebrityDetailsinput) {
  createCelebrity(celebrity: $celebrity) {
    id
    dimension_name
    celebrity_name
    celebrity_role
    celebrity_hover_info
    celebrity_info
    celebrity_picture_url
    createdAt
    updatedAt
  }
}`;

export const GQ_UPDATE_CELEBRITY = `mutation UpdateCelebrity($celebrity: CelebrityDetailsUpdateinput) {
  updateCelebrity(celebrity: $celebrity) {
    id
    dimension_name
    celebrity_name
    celebrity_role
    celebrity_hover_info
    celebrity_info
    celebrity_picture_url
    createdAt
    updatedAt
  }
}`;
export const GQ_DELETE_CELEBRITY = `mutation DeleteCelebrity($deleteCelebrityId: ID) {
  deleteCelebrity(id: $deleteCelebrityId) {
   message
  }
}`;


