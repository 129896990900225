export const GQ_LOGIN = `mutation AdminLogin($email: String, $password: String) {
  adminLogin(email: $email, password: $password) {
      id
    full_name
    email
    role
    permissions {
      parent {
        view
        edit
      }
      children {
        view
        edit
      }
      provider {
        view
        edit
      } 
      school {
        view
        edit
      }
      free_slot {
        edit
        view
      }
      free_talent_advisory_call {
        view
        edit
      }
      free_assessment_users {
        view
        edit
      }

      reg_dropouts{
        view
        edit
      }

      payment_tracker{
        view
        edit
      }
      
      download_report{
        view
        edit
      }
      affiliates{
        view
        edit
      }

      child_upgrade {
        view
        edit
      }
      school_enquiries{
        view
        edit
      }
      report_section {
        view
        edit
      }

      payment_link{
        view
        edit
      }

      counselling_calls{
        view
        edit
      }
   
      coupons{
        view
        edit
      }
    
      contact_us{
        view
        edit
      }
      cms {
        edit
        view
      }
      notification {
        edit
        view
      }
      feedback {
        view
        edit
      }
      courses {
        view
        edit
      }
      sub_admin {
        view
        edit
      }
       regime {
        view
        edit
      } 
      deleted_users{
       view
        edit
      }
    }
    grades
    grade_sections {
      grade
      section
    }
  } 
}`;

export const GQ_SENT_ADMIN_VERIFICATION_OTP = `mutation SentAdminVerificationOTP($email: String) {
  sentAdminVerificationOTP(email: $email) {
    id
    email
  }
}`;

export const GQ_VERIFY_ADMIN_EMAIL = `mutation VerifyAdminEmail($email: String, $otp: Int) {
  verifyAdminEmail(email: $email, otp: $otp) {
    token
  }
}`;

export const GQ_REGISTER = `mutation Mutation($user: UserCreateinput) {
  createUser(user: $user) {
  id
  first_name
  last_name
    email
    phone
    token
    role
    childCount
  }
}`;

export const GQ_EMAIL_VERIFICATION = `mutation Mutation($email: String) {
  verifyEmail(email: $email) {
    id
  }
}`;


export const GQ_PROVIDER_EMAIL_VERIFICATION = `mutation Mutation($email: String) {
  verifyProviderEmail(email: $email) {
    id
  }
}`;

export const GQ_PARENT_EMAIL_VERIFICATION = `mutation VerifyEmail($email: String) {
  verifyEmail(email: $email) {
    id
    email
  }
}`;



export const GQ_PARENT_EARLY_ACCESS = `mutation ParentEarlyAccess($user: parentEarlyAccessInput) {
  parentEarlyAccess(user: $user) {
    id
    emailVerifiedAt
    phoneVerifiedAt
  }
}`

export const GQ_PROVIDER_REGISTER = `mutation Mutation($user: UserCreateinput) {
  createUser(user: $user) {
  id
  first_name
  last_name
    email
    phone
    token
    role
    business_name
    childCount
  }
}`


export const GQ_GENERATE_OTP = `  mutation Mutation($email: String, $phone: String) {
    generateOtp(email: $email, phone: $phone) {
      otp
    }
  }`

export const GQ_VERIFY_PROVIDER_OTP = `mutation Mutation($phone: String, $otp: Int) {
  verifyProviderOtp(phone: $phone, otp: $otp) {
    id
  }
}`

export const GQ_VERIFY_OTP = `mutation Mutation($otp: Int, $phone: String) {
  verifyOtp(otp: $otp, phone: $phone) {
    id
    phone
    otp
  }
}`

export const GQ_VERIFY_PARENT_OTP = `mutation Mutation($phone: String, $otp: Int) {
  verifyParentOtp(phone: $phone, otp: $otp) {
    id
    phone
  }
}`

export const GQ_GENERATE_PARENT_TOKEN = `mutation GenerateParenttoken($email: String, $phone: String) {
  generateParenttoken(email: $email, phone: $phone) {
    tokenNumber
  }
}`;

export const GQ_FORGOT_PASSWORD = `mutation Mutation($email: String) {
  forgetPassword(email: $email) {
    otp
    email
  }
}`;

export const GQ_GENERATE_OTP_PARENT = `mutation GenerateOtpParent($phone: String) {
  generateOtpParent(phone: $phone) {
    id
  }
}`;

export const GQ_SENT_ADMIN_PASSWORD_RESET_OTP = `mutation SentAdminPasswordResetOTP($email: String) {
  sentAdminPasswordResetOTP(email: $email) {
    id
  }
}`;
export const GQ_VERIFY_ADMIN_FORGOT_EMAIL = `mutation VerifyAdminForgotEmail($email: String, $otp: Int) {
  verifyAdminForgotEmail(email: $email, otp: $otp) {
    id
  }
}`;

export const GQ_RESET_PASSWORD = `mutation ResetAdminPassword($data: PasswordResetInput) {
  resetAdminPassword(data: $data) {
    id
  }
}`;

export const GQ_GENERATE_PARENT_PASSWORD = `mutation GenerateParentPassword($email: String, $password: String, $confirmPassword: String) {
  generateParentPassword(email: $email, password: $password, confirm_password: $confirmPassword) {
    id
  }
}`;

