export const GQ_GET_ATTRIBUTE_WEIGHTAGE = `query GetAttributeWeightage($attributeName: String, $title: String) {
  getAttributeWeightage(attribute_name: $attributeName, title: $title) {
    id
    option_type
    option_values
    dimension_name
    weightage
  }
}`;

export const GQ_UPDATE_ATTRIBUTE_WEIGHTAGE = `mutation UpdateAttributeWeightage($attribute: AttributeWeightageInput) {
  updateAttributeWeightage(attribute: $attribute) {
    id
  }
}`;

export  const GQ_GET_FAMILY_ATRRIBUTE = `query GetReportWeightageDetails($options: [String]) {
  getReportWeightageDetails(options: $options) {
    option_type
  }
}`;

export const GQ_GET_FAMILY_ATTRIBUTE_DETAILS = `query GetReportWeightageDetailsByOption($optionType: String) {
  getReportWeightageDetailsByOption(option_type: $optionType) {
    id
    option_type
    option_values
    dimension_name
    weightage
  }
}`;

// sub admin

export const GQ_CREATE_SUB_ADMIN = `mutation CreateSubAdmin($fullName: String, $email: String) {
  createSubAdmin(full_name: $fullName, email: $email) {
    id
  }
}`;

export const GQ_DELETE_SUB_ADMIN = `mutation DeleteSubAdmin($deleteSubAdminId: String) {
  deleteSubAdmin(id: $deleteSubAdminId)
}`;

export const GQ_UPDATE_SUB_ADMIN_PERMISSIONS = `mutation UpdateSubAdminPermissions($input: PerissonsInput) {
  updateSubAdminPermissions(input: $input) {
    id
   full_name
    permissions {
      parent {
        view
        edit
      }
      children {
        view
        edit
      }
      provider {
        view
        edit
      }
      school {
        view
        edit
      }
      free_slot {
        view
        edit
      }
      free_talent_advisory_call {
        view
        edit
      }
      free_assessment_users {
        view
        edit
      }

      reg_dropouts{
        view
        edit
      }

      payment_tracker{
        view
        edit
      }

      download_report{
        view
        edit
      }

      affiliates{
        view
        edit
      }

      child_upgrade{
        view
        edit
      }
      school_enquiries{
        view
        edit
      }

      report_section {
        view
        edit
      }


      payment_link{
        view
        edit
      }

      counselling_calls{
        view
        edit
      }

    
      coupons{
        view
        edit
      }
      contact_us{
        view
        edit
      }
      cms {
        view
        edit
      }
      notification {
        view
        edit
      }
      feedback {
        view
        edit
      }
      courses {
        view
        edit
      }
      sub_admin {
        view
        edit
      }
       regime {
        view
        edit
      }
      deleted_users {
        view
        edit
      }
    }
  }
}`;

export const GQ_GET_ALL_SUB_ADMINS = `query GetAllSubAdmins {
  getAllSubAdmins {
    id
    email
    full_name
    createdAt
    createdBy {
      full_name
      id
      email
    }
  }
}`;

export const GQ_GET_SUB_ADMIN_BY_ID = `query GetSubAdminById($getSubAdminByIdId: String) {
  getSubAdminById(id: $getSubAdminByIdId) {
    id
    role
   full_name
    email
    school_name
    grade_sections{
        grade
        section
    }
    permissions {
      parent {
        view
        edit
      }
     children {
      view
        edit
      }
      provider {
        view
        edit
      }
      school{
       view
        edit
        }
      free_slot {
        view
        edit
      }
      free_talent_advisory_call {
        view
        edit
      }
      free_assessment_users {
        view
        edit
      }

      reg_dropouts{
        view
        edit
      }

      payment_tracker{
        view
        edit
      }

      affiliates{
        view
        edit
      }

      download_report{
        view
        edit
      }

      report_section {
        view
        edit
      }
      
      child_upgrade{
        view
        edit
      }
      school_enquiries{
        view
        edit
      }

      payment_link{
        view
        edit
      }

      counselling_calls{
        view
        edit
      }

      coupons{
        view
        edit
      }
      contact_us{
        view
        edit
      }
      cms {
        view
        edit
      }
      notification {
        view
        edit
      }
      feedback {
        view
        edit
      }
      courses {
        view
        edit
      }
      sub_admin {
        view
        edit
      }
      regime {
        view
        edit
      }
      deleted_users {
        view
        edit
      }
    }
  }
}`;
