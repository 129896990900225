import {lazy} from 'react';
import {DEFAULT_PATHS} from 'config.js';

const NotFound = lazy(() => import('../views/default/Login'));
const ProvideEmailVerified = lazy(() => import('../views/default/ProviderEmailVerification'));
const Login = lazy(() => import('../views/default/Login'));
const ParentEmailVerified = lazy(() => import('../views/default/ParentEmailVerification'));
const MobileVerification = lazy(() => import('../views/default/MobileVerification'));
const ForgotPassword = lazy(() => import('../views/default/ForgotPassword'));
const Register = lazy(() => import('../views/default/Register'));
const ProviderRegister = lazy(() => import('../views/default/ProviderRegister'));
const ResetPassword = lazy(() => import('../views/default/ResetPassword'));
const Unauthorized = lazy(() => import('../views/default/Unauthorized'));
const InvalidAccess = lazy(() => import('../views/default/InvalidAccess'));
const App = lazy(() => import('../App.js'));
const Home = lazy(() => import('../views/default/Home'));

/*
{ path: "/path", exact: true, component: ViewHome },
// or
{ path: "/path", component: ViewHome},
// or
{ path: "/path", exact: true, redirect: true, to: "/redirectPath" },
*/
const defaultRoutes = [
    {path: DEFAULT_PATHS.NOTFOUND, exact: true, component: NotFound},
    {path: DEFAULT_PATHS.LOGIN, exact: true, component: Login},
    {path: DEFAULT_PATHS.PROVIDER_EMAIL_VERIFICATION + '/:token', exact: true, component: ProvideEmailVerified},
    {path: DEFAULT_PATHS.VERIFY_MOBILE + '/:phone', exact: true, component: MobileVerification},
    {path: DEFAULT_PATHS.LOGIN + '/:token', component: Login},
    {path: DEFAULT_PATHS.PARENT_EMAIL_VERIFIED + '/:token', component: ParentEmailVerified},
    {path: DEFAULT_PATHS.REGISTER, exact: true, component: Register},
    {path: DEFAULT_PATHS.PROVIDER_REGISTER, exact: true, component: ProviderRegister},
    {path: DEFAULT_PATHS.FORGOT_PASSWORD, exact: true, component: ForgotPassword},
    {path: DEFAULT_PATHS.RESET_PASSWORD, exact: true, component: ResetPassword},
    // {path: '/child/loader', exact: true, component: LoadingProcessView},
    {path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: Unauthorized},
    {path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: InvalidAccess},
    {path: DEFAULT_PATHS.APP, component: App},
    {path: '/', exact: true, component: Home},
];

export default defaultRoutes;
