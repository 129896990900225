import {createSlice} from '@reduxjs/toolkit';

const isLogin = localStorage.getItem('aark_auth');

const getUserData = function () {
    let user = JSON.parse(localStorage.getItem("userData"));
    return user ? user : {}
};


const initialState = {
    isLogin,
    currentUser: isLogin ? getUserData() : {},
    childData: [],
    callApi: '1'

};

const setUserData = function (userData) {
    localStorage.setItem("userData", JSON.stringify(userData));
};

const authSlice = createSlice({
        name: 'auth',
        initialState,
        reducers: {
            setCurrentUser(state, action) {
                setUserData(action.payload)
                state.currentUser = action.payload;
                state.isLogin = action.payload.token;
            },
            setChild(state, action) {
                state.childData = action.payload ?? [];
            },
            setCallAPI(state, action) {
                state.callApi = action.payload;
            },
        },
    })
;

export const {setCurrentUser, setChild,setCallAPI} = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
