import {Col} from "react-bootstrap";
import React, {useEffect} from "react";



const LoadingProcessView = (props) => {
    let current = 0,
        slides = document.getElementsByTagName("text");
    let imgSlider = document.getElementsByTagName("image");

    useEffect(() => {
        const timer = setTimeout(() => props.history.push('/child/list'), 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timeoutOne = setInterval(function () {
            for (var i = 0; i < slides.length; i++) {
                slides[i].style.opacity = 0;
            }
            current = (current !== slides.length - 1) ? current + 1 : 0;
            slides[current].style.opacity = 1;
        }, 2000);
        const timeoutTwo = setInterval(function () {
            for (var i = 0; i < imgSlider.length; i++) {
                imgSlider[i].style.opacity = 0;
            }
            current = (current !== imgSlider.length - 1) ? current + 1 : 0;
            imgSlider[current].style.opacity = 1;
        }, 2000);
        return () => {clearInterval(timeoutOne);clearInterval(timeoutTwo)};
    }, [props]);

    return (
        <>
            <Col className="loader">
                <Col md="6" className="image-box">
                    <image>
                        <img className="img-fluid mb-2" src="/img/illustration/access-token.webp"/>
                    </image>
                    <image>
                        <img className="img-fluid mb-2" src="/img/illustration/icon-performance.webp"/>
                    </image>
                    <image>
                        <img className="img-fluid mb-2" src="/img/illustration/icon-storage.webp"/>
                    </image>
                </Col>
                <Col md="6" className="text-box">
                    <text>Personalizing your dashboard</text>
                    <text>Preparing courses for you</text>
                    <text>Ready to learn with fun</text>
                </Col>
                <div className="ball-loader">
                    <div className="ball-loader-ball ball1"></div>
                    <div className="ball-loader-ball ball2"></div>
                    <div className="ball-loader-ball ball3"></div>
                </div>
            </Col>
        </>
    )
}


export default LoadingProcessView;
